import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../API_PATH';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandsPraying } from "@fortawesome/free-solid-svg-icons";
import "./CSS/PriestPrayer.css";

export const PrayersList = () => {
    const [peopleData, setPeopleData] = useState({});
    const [prayerRequests, setPrayerRequests] = useState([]);
    const [allPriests, setAllPriests] = useState([]);
    const [prayerCount, setPrayerCount] = useState([]);

    useEffect(() => {
        const storedEmail = sessionStorage.getItem("userEmail");
        const storedSub = sessionStorage.getItem("userSub");

        // Fetch people details
        axios.get(`${API_PATH}/pray_for_priest/get_people_details.php`)
            .then(response => {
                const matchedPeople = response.data.find(people =>
                    (storedEmail && people.email === storedEmail) ||
                    (storedSub && people.sub === storedSub)
                );

                if (matchedPeople) {
                    setPeopleData(matchedPeople);
                }
            })
            .catch(error => console.error("Error fetching people details:", error));
    }, []);

    useEffect(() => {
        // Fetch all prayer requests
        axios.get(`${API_PATH}/pray_for_priest/prayer_request.php`)
            .then(response => {
                const sortedPrayers = response.data.sort((a, b) => new Date(b.posted_on) - new Date(a.posted_on));
                setPrayerRequests(sortedPrayers);
            })
            .catch(error => console.error("Error fetching prayer requests:", error));
    }, []);

    useEffect(() => {
        // Fetch all priests
        axios.get(`${API_PATH}/pray_for_priest/get_priest_details.php`)
            .then(response => {
                setAllPriests(response.data);
            })
            .catch(error => console.error("Error fetching priests details:", error));
    }, []);

    useEffect(() => {
        // Fetch prayer count data
        axios.get(`${API_PATH}/pray_for_priest/prayering_support.php`)
            .then(response => {
                setPrayerCount(response.data);
            })
            .catch(error => console.error("Error fetching prayer count details:", error));
    }, []);

    const handlePrayerClick = (prayerId, priestId) => {
        if (!peopleData.people_id) {
            alert("User not identified. Please log in again.");
            return;
        }

        // Check if the prayer is already clicked (blue color)
        const isClicked = prayerCount.some(
            entry =>
                entry.people_id === peopleData.people_id &&
                entry.prayer_id === prayerId &&
                entry.priest_id === priestId
        );

        if (isClicked) {
            // DELETE request to remove the prayer log
            const data = {
                people_id: peopleData.people_id,
                prayer_id: prayerId,
                priest_id: priestId
            };

            axios.delete(`${API_PATH}/pray_for_priest/prayer_count.php`, { data })
                .then(response => {
                    if (response.data.success) {
                        setPrayerCount(prev =>
                            prev.filter(
                                entry =>
                                    !(
                                        entry.people_id === data.people_id &&
                                        entry.prayer_id === data.prayer_id &&
                                        entry.priest_id === data.priest_id
                                    )
                            )
                        );
                    } else {
                        alert("Failed to delete the prayer. Please try again.");
                    }
                })
                .catch(error => console.error("Error deleting prayer:", error));
        } else {
            // POST request to add the prayer log
            const data = {
                people_id: peopleData.people_id,
                prayer_id: prayerId,
                priest_id: priestId
            };

            axios.post(`${API_PATH}/pray_for_priest/prayer_count.php`, data)
                .then(response => {
                    if (response.data.success) {
                        setPrayerCount(prev => [...prev, data]);
                    } else {
                        alert("Failed to register the prayer. Please try again.");
                    }
                })
                .catch(error => console.error("Error posting prayer:", error));
        }
    };

    const getPrayerIconClass = (prayerId, priestId) => {
        const isPrayerClicked = prayerCount.some(
            (prayer) =>
                prayer.people_id === peopleData.people_id &&
                prayer.prayer_id === prayerId &&
                prayer.priest_id === priestId
        );
        return isPrayerClicked ? "hand-icon-green" : "hand-icon-gray";
    };

    const formatCount = (count) => {
        if (count >= 1000) {
            return (count / 1000).toFixed(1) + 'K'; 
        }
        return count;
    };

    return (
        <div>
            <div className="prayers-list">
                {prayerRequests.map((prayer) => {
                    // Find the priest details by matching priest_id
                    const priest = allPriests.find(p => p.priest_id === prayer.priest_id);
                    const priestName = priest ? priest.priest_name : "Unknown Priest";
                    const profileImage = priest ? `${API_PATH}/pray_for_priest/${priest.profile_image}` : `${process.env.PUBLIC_URL}/images/dp.png`;

                    // Calculate total prayer count for this prayer_id
                    const totalPrayerCount = prayerCount.filter(entry => entry.prayer_id === prayer.prayer_id).length;

                    return (
                        <div className="prayers-single" key={prayer.id}>
                            <div className="text-center align-items-center">
                                <div className="prayers-img-div">
                                    <img className="img-fluid prayers-img" src={profileImage} alt={priestName} />
                                </div>
                                <p className="prayer-name">{priestName}</p>
                            </div>
                            <div>
                                <p className='prayers-text-content'>{prayer.prayer_text}</p>
                                <div className="prayers-count">
                                    <i
                                        className={getPrayerIconClass(prayer.prayer_id, prayer.priest_id)}
                                        onClick={() => handlePrayerClick(prayer.prayer_id, prayer.priest_id)}
                                    >
                                        <FontAwesomeIcon icon={faHandsPraying} />
                                    </i>
                                    <i className="count-prayers">
                                        <b className="count">{formatCount(totalPrayerCount)}</b> Prayers
                                    </i>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

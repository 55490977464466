import './CSS/Admin.css';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Register } from "./Register";
import { useState } from 'react';
import axios from 'axios';
import { API_PATH } from "../API_PATH";
import { Home } from './Home';

export const Admin = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [forgotVisible, setForgotVisible] = useState(false);
    const [logInVisible, setlogInVisible] = useState(true);
    const [otpVisible, setOtpVisible] = useState(false);

    const [adminName, setAdminName] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");

    // const [adminMobile, setAdminMobile] = useState("");
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const [otpEntered, setOtpEntered] = useState("");
    const [otpVerified, setOtpVerified] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleNav = () => {
        navigate("/admin/register")
    };

    const handleForgotPassword = () => {
        setForgotVisible(true);
        setlogInVisible(false);
    };

    const handleBackToLogin = () => {
        setForgotVisible(false); 
        setlogInVisible(true);
        setOtpVisible(false)
    };

    const handleOtp = () => {
        setOtpVisible(true)
        setForgotVisible(false);
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoginError("");
    
        try {
            const response = await axios.post(`${API_PATH}/pray_for_priest/login.php`, {
                admin_name: adminName,
                password: password,
            });
    
            if (response.data.status === 'success') {
                sessionStorage.setItem('admin', adminName);
                navigate("/admin/home");
                setAdminName();
                setPassword();
            } else {
                setLoginError(response.data.message || "Invalid admin name or password.");
            }
        } catch (error) {
            console.error("Login error:", error);
            setLoginError("An error occurred. Please try again.");
        }
    };

    const handleAdminNameChange = async (e) => {
        const name = e.target.value;
        setAdminName(name);

        const timer = setTimeout(async () => {
            if (name) {
                try {
                    const response = await axios.post(`${API_PATH}/pray_for_priest/forgot_admin.php`, { admin_name: name });
                    
                    if (response.data.status === 'available') {
                        setLoginError("");
                        // setAdminMobile(response.data.mobile);
                    } else {
                        setLoginError("Admin name not available.");
                        // setAdminMobile("");
                    }
                } catch (error) {
                    console.error("Error checking admin name:", error);
                    setLoginError("Error checking admin name.");
                }
            } else {
                setLoginError(""); 
                // setAdminMobile(""); 
            }
        }, 2000);
    
        return () => clearTimeout(timer); 
    };

    const handleSendOtp = async (e) => {
        e.preventDefault();
        setOtpError("");
    
        if (!adminName) {
            setOtpError("Admin name must be entered.");
            return;
        }
    
        // Generate a random 4-digit OTP
        const randomOtp = Math.floor(1000 + Math.random() * 9000).toString();
        setOtp(randomOtp);
    
        try {
            const response = await axios.post(`${API_PATH}/pray_for_priest/send_otp.php`, {
                admin_name: adminName,
                otp: randomOtp
            });
    
            if (response.data.status === 'success') {
                setOtpError("");
                handleOtp();
            } else {
                setOtpError("Failed to send OTP. Please try again.");
            }
        } catch (error) {
            console.error("Error sending OTP:", error);
            setOtpError("An error occurred while sending OTP.");
        }
    };

    const handleOtpVerify = async (e) => {
        e.preventDefault();
        if (otpEntered === otp) {
            setOtpVerified(true);
            setSuccessMessage("OTP verified successfully.");
            setErrorMessage("");
        } else {
            setErrorMessage("Invalid OTP. Please try again.");
            setSuccessMessage("");
        }
    };

    const validatePassword = (password) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        return hasUpperCase && hasNumber && hasSpecialChar;
    };

    const handlePasswordUpdate = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        if (!validatePassword(newPassword)) {
            setErrorMessage("Password must contain at least one uppercase letter, one number, and one special character.");
            return;
        }
            try {
                const response = await axios.post(`${API_PATH}/pray_for_priest/update_password.php`, {
                    admin_name: adminName,
                    password: newPassword,
                });

                if (response.data.status === 'success') {
                    alert("Password updated successfully!");
                    handleBackToLogin();
                } else {
                    setErrorMessage("Failed to update password. Please try again.");
                }
            } catch (error) {
                console.error("Error updating password:", error);
                setErrorMessage("An error occurred while updating the password.");
            }
    };
    

    return (
        <div>
            {location.pathname === "/admin" && (
            <div className="background" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/login-background.jpg)` }}>
                <div className="container">
                    {logInVisible && (
                    <div className={`row justify-content-center ${forgotVisible ? 'hide-login' : 'dividers'}`}>
                        <div className="col-lg-4 col-md-12 mb-4">
                            <div className="card bg-dark text-white border-0" style={{ boxShadow: "-15px 0px 13px black" }}>
                                <img src={`${process.env.PUBLIC_URL}/images/login-side.jpg`} className="card-img" alt="Login Side" />
                                <div className="card-img-overlay d-flex justify-content-center align-items-center">
                                    <div>
                                        <h5 className="card-title text-center">Welcome Back</h5>
                                        <p className="card-text text-center opacity-50">
                                            This is a wider card with supporting text below as a natural lead-in to additional content.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12" style={{ color: "white", boxShadow: "15px 0px 13px black" }}>
                            <div className="d-flex justify-content-center mt-2">
                                <h4 className="fs-2">Welcome Admin!:)</h4>
                            </div>
                            <form onSubmit={handleLogin}>
                                <div className="mb-3">
                                    <label className="form-label">Admin Name</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        value={adminName} 
                                        onChange={(e) => setAdminName(e.target.value)} 
                                    />
                                    <div className="form-text opacity-50" style={{ color: "white" }}>We'll never share your Name with anyone else.</div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        value={password} 
                                        onChange={(e) => setPassword(e.target.value)} 
                                    />
                                    <p className="form-text opacity-75" style={{ color: "white", cursor: "pointer" }} onClick={handleForgotPassword}>Forgot Password</p>
                                </div>
                                {loginError && <div style={{fontSize: "13px"}} className="text-danger mb-4">{loginError}</div>}
                                <button type="submit" className="button">Log IN</button>
                                <div className="form-text text-end opacity-75" style={{ color: "white" }}><p>or sign up using <br /><b onClick={handleNav} style={{ color: "rgb(255, 215, 0)", cursor: "pointer" }}>SIGN UP</b></p></div>
                            </form>
                        </div>
                    </div>
                    )}
                    {forgotVisible && (
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="col-lg-4 col-md-8 col-sm-10">
                            <form style={{ color: "white", padding: "20px", borderRadius: "10px", boxShadow: "10px 5px 10px black" }}>
                                <h4 className="text-center">Reset Password</h4>
                                <div className="mb-3">
                                    <label className="form-label">Admin Name</label>
                                    <input type="text" className="form-control" value={adminName} onChange={handleAdminNameChange} />
                                    {loginError && <div className="text-danger">{loginError}</div>}
                                </div>
                                <button type="submit" className="button" onClick={handleSendOtp}>Send OTP</button>
                                {otpError && <div className="text-danger">{otpError}</div>}
                                <div className="text-end mt-3" style={{ cursor: "pointer", color: "#ffc107", fontSize: "12px" }} onClick={handleBackToLogin}>Back to Login</div>
                            </form>
                        </div>
                    </div>
                    )}
                    {otpVisible && (
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="col-lg-4 col-md-8 col-sm-10">
                            <form style={{ color: "white", padding: "20px", borderRadius: "10px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)" }} onSubmit={handleOtpVerify}>
                                <h4 className="text-center">Reset Password</h4>
                                <div className="mb-3">
                                    <label className="form-label">Enter Four Digit OTP</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        value={otpEntered} 
                                        onChange={(e) => setOtpEntered(e.target.value)} 
                                    />
                                </div>
                                {successMessage && <div className="text-success">{successMessage}</div>}
                                {errorMessage && <div className="text-danger">{errorMessage}</div>}
                                <button type="submit" className="button">Verify OTP</button>
                                {otpVerified && (
                                    <>
                                        <div className="mb-3">
                                            <label className="form-label">New Password</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                value={newPassword} 
                                                onChange={(e) => setNewPassword(e.target.value)} 
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Confirm Password</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                value={confirmPassword} 
                                                onChange={(e) => setConfirmPassword(e.target.value)} 
                                            />
                                        </div>
                                        <button type="submit" className="button" onClick={handlePasswordUpdate}>Submit</button>
                                    </>
                                )}
                                <div className="text-end mt-3" style={{ cursor: "pointer", color: "#ffc107", fontSize: "12px" }} onClick={handleBackToLogin}>Back to Login</div>
                            </form>
                        </div>
                    </div>
                    )}
                </div>
            </div>
            )}
            <Routes>
                <Route path='/register' element={<Register />} />
                <Route path='/home' element={<Home/>} />
                {location.pathname !== '/admin' && (
                    <Route path='*' element={<NotFound />} />
                )}
            </Routes>
        </div>
    );
};

export default Admin;

const NotFound = () => {
    return (
      <div className="text-center mt-5">
        <h1>404 - Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    );
  };
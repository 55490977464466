import "bootstrap/dist/css/bootstrap.min.css";
import "./CSS/ComingSoon.css";

export const ComingSoon = () => {
    return (
        <div className="coming-soon d-flex justify-content-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/coming.png)` }}>
            <div className="container text-white text-center mt-5">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 w-100">
                        <h3 className="coming soon-h3">Luke 12:49 - Launching soon</h3>
                        <h4 className="coming soon-h4">"I came to cast fire upon the earth, and would that it were already kindled!"</h4>
                        <h5 className="coming soon-h5">Igniting Faith, One Prayer at a Time</h5>
                        <p className="coming soon-para">We are uniting hearts to strengthen priests and transform lives through prayer. Together, we’ll ignite a flame of faith and renew the world.</p>
                        <h6 className="coming soon-h6">Join the movement. Be part of a divine mission.</h6>
                        <div className="coming-btn text-center">
                            <a className="update-soon me-3" href="https://stmichaelcenter.flocknote.com/PrayforPriests" aria-label="Sign up to pray for priests">Sign Up</a>
                            <a className="update-soon mt-3" href="https://saintmichaelusa.org/" target="_blank" rel="noopener noreferrer" aria-label="Visit St Michael Center">A Project of the St Michael Center</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

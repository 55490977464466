import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { API_PATH } from '../API_PATH';

export const ResetPeoplePassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const location = useLocation();

    // Extract token from URL
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    const handleResetPassword = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${API_PATH}/pray_for_priest/reset_people_password.php`, {
                token: token,
                password: newPassword,
            });

            if (response.data.status === 'success') {
                setMessage('Password reset successfully!');
            } else {
                setMessage(response.data.message);
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            setMessage('Error resetting password');
        }
    };

    return (
        <div>
            <h2>Reset Password</h2>
            <form onSubmit={handleResetPassword}>
                <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <button type="submit">Reset Password</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../API_PATH';
import "./CSS/Following.css";

export const Following = () => {
    const [peopleData, setPeopleData] = useState({});
    const [allPriests, setAllPriests] = useState([]);
    const [followData, setFollowData] = useState([]);
    const [followingPriests, setFollowingPriests] = useState([]);

    useEffect(() => {
        // Fetch follow data
        axios.get(`${API_PATH}/pray_for_priest/get_follow_priests.php`)
            .then(response => {
                setFollowData(response.data);
            })
            .catch(error => console.error("Error fetching follow data:", error));
    }, []);

    useEffect(() => {
        const storedEmail = sessionStorage.getItem("userEmail");
        const storedSub = sessionStorage.getItem("userSub");

        // Fetch people details
        axios.get(`${API_PATH}/pray_for_priest/get_people_details.php`)
            .then(response => {
                const matchedPeople = response.data.find(people =>
                    (storedEmail && people.email === storedEmail) ||
                    (storedSub && people.sub === storedSub)
                );

                if (matchedPeople) {
                    setPeopleData(matchedPeople);
                }
            })
            .catch(error => console.error("Error fetching people details:", error));
    }, []);

    useEffect(() => {
        // Fetch all priests
        axios.get(`${API_PATH}/pray_for_priest/get_priest_details.php`)
            .then(response => {
                const priestsWithFollowStatus = response.data.map(priest => {
                    const isFollowed = followData.some(follow =>
                        follow.people_id === peopleData.people_id && follow.priest_id === priest.priest_id
                    );
                    return { ...priest, followed: isFollowed };
                });
                setAllPriests(priestsWithFollowStatus);
            })
            .catch(error => console.error("Error fetching priests details:", error));
    }, [peopleData, followData]);

    useEffect(() => {
        // Filter to find priests followed by the current user
        if (peopleData.people_id && followData.length > 0 && allPriests.length > 0) {
            const followingList = allPriests.filter(priest => {
                // Check if the priest is followed by comparing IDs in followData
                return followData.some(follow =>
                    follow.people_id === peopleData.people_id && follow.priest_id === priest.priest_id
                );
            });

            // Add follower count for each priest in the following list
            const updatedFollowingList = followingList.map(priest => {
                const followerCount = followData.filter(follow => follow.priest_id === priest.priest_id).length;
                return { ...priest, followerCount };
            });

            setFollowingPriests(updatedFollowingList);
        }
    }, [peopleData, followData, allPriests]);

    const handleFollowToggle = (priest) => {
        const data = {
            people_id: peopleData.people_id,
            priest_id: priest.priest_id,
        };
    
        axios.post(`${API_PATH}/pray_for_priest/follow_priest.php`, data)
            .then(response => {
                if (response.data.status === 'followed') {
                    setAllPriests(prevAllPriests =>
                        prevAllPriests.map(p =>
                            p.priest_id === priest.priest_id ? { ...p, followed: true } : p
                        )
                    );
                } else if (response.data.status === 'unfollowed') {
                    setAllPriests(prevAllPriests =>
                        prevAllPriests.map(p =>
                            p.priest_id === priest.priest_id ? { ...p, followed: false } : p
                        )
                    );
                }
            })
            .catch(error => console.error("Error toggling follow status:", error));
        };

        const formatCount = (count) => {
            if (count >= 1000) {
                return (count / 1000).toFixed(1) + 'K'; 
            }
            return count;
        };

    return (
        <div className="following-list">
            {followingPriests.map((priest, index) => (
                <div className="following" key={index}>
                    <div className="following-img-div">
                        <img className='img-fluid following-img' src={`${API_PATH}/pray_for_priest/${priest.profile_image}`} alt="" />
                    </div>
                    <div className="following-content">
                        <div>
                            <b className="following-priest">{priest.priest_name}</b><br />
                            <i className="following-loc">{priest.country}</i><br />
                            <i className="following-loc">{priest.city}</i><br />
                            <i className="following-loc">{priest.email}</i><br />
                            <i className="following-loc">Follower's <b className="following-priest">{formatCount(priest.followerCount)}</b></i>
                        </div>
                        <div className="following-follow" onClick={() => handleFollowToggle(priest)}>
                            <b className="following-follow-font">{priest.followed ? "Unfollow" : "Follow"}</b>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import './CSS/Home.css';
import AOS from "aos";
import "aos/dist/aos.css";

export const Home = () => {

    const location = useLocation();
    const homeButtonRef = useRef(null);
    // const videoRef = useRef(null);

    useEffect(() => {
        AOS.init();
      }, []);

    useEffect(() => {
        const progress = document.querySelector('.carousel-progress');
        const carousel = document.querySelector('#carouselExampleIndicators');

        carousel.addEventListener('slide.bs.carousel', () => {
            // Reset progress to 0% at each slide
            progress.style.width = '0%';

            // Animate progress to 100% over the interval duration
            setTimeout(() => {
                progress.style.width = '100%';
            }, 10); // Slight delay to allow reset
        });

        // Initialize progress for the first slide
        progress.style.width = '100%';
    }, []);

    useEffect(() => {
        if (location.pathname === "/home" && homeButtonRef.current) {
          homeButtonRef.current.click();
        }
      }, [location]);

      useEffect(() => {
        const carousel = document.querySelector('#carouselExampleIndicators');
        const items = document.querySelectorAll('.carousel-item');
    
        const handleSlide = () => {
            items.forEach((item) => {
                if (item.classList.contains('active')) {
                    item.classList.add('active'); // Add active class for drop-down animation
                } else {
                    item.classList.remove('active'); // Remove active class
                }
            });
        };
    
        carousel.addEventListener('slide.bs.carousel', handleSlide);
    
        // Initialize the first slide
        handleSlide();
    
        return () => {
            carousel.removeEventListener('slide.bs.carousel', handleSlide);
        };
    }, []);

    // useEffect(() => {
    //     const videoElement = videoRef.current;
    
    //     // Play video when the component is mounted (user enters screen)
    //     if (videoElement) {
    //       videoElement.play();
    //     }
    
    //     return () => {
    //       // Pause video when the component is unmounted (user exits screen)
    //       if (videoElement) {
    //         videoElement.pause();
    //         videoElement.currentTime = 0; // Reset to the beginning
    //       }
    //     };
    //   }, []);

    return (
        <div>
            <section >
                <div
                    style={{zIndex:"-1"}}
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-bs-ride="carousel"
                    data-bs-interval="5000" // Speed in milliseconds
                >
                    {/* Carousel Items */}
                    <div className="carousel-inner">
                        <div className="carousel-item active carousel-content">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/crou-2.png`}
                                className="d-block w-100"
                                alt="Chuch slide"
                            />
                            <div className="carousel-text-div">
                                <div className="text-center">
                                    <h3 className="caro-text caro-h3">Ignite a Divine Fire</h3>
                                    <h5 className="caro-text caro-h5">Luke 12:49</h5>
                                    <p className="caro-text caro-para">"I have come to set the earth on fire, and how I wish it were already blazing!" </p>
                                    {/* <p className="caro-text caro-para">Join the Divine Blaze! Your prayers transform hearts and nations. Pray for priests and become part of a global movement to strengthen them. </p> */}
                                    <h6 className="caro-text caro-h6">Sign Up now and light the way for souls.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item carousel-content">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/crou-3.png`}
                                className="d-block w-100"
                                alt="Chuch slide"
                            />
                            <div className="carousel-text-div">
                                <div className="text-center">
                                    <h3 className="caro-text caro-h3">Pray to Strengthen Our Priests</h3>
                                    <p className="caro-text caro-para">Unite in prayer for priests. Strengthen their mission by offering your prayers and sacrifices. Sign up today and empower the shepherds of Christ.</p>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item carousel-content">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/crou-4.png`}
                                className="d-block w-100"
                                alt="Chuch slide"
                            />
                            <div className="carousel-text-div">
                                <div className="text-center">
                                    <h3 className="caro-text caro-h3">A Call to All Believers: Stand with Our Priests</h3>
                                    <p className="caro-text caro-para">Your prayers are crucial for priests on the frontlines. Become part of the mission to uphold them with your prayers and support those leading souls to Christ.</p>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item carousel-content">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/crou-1.png`}
                                className="d-block w-100"
                                alt="Chuch slide"
                            />
                            <div className="carousel-text-div">
                                <div className="text-center">
                                    <h3 className="caro-text caro-h3">Your Prayers: A Shield for the Shepherds</h3>
                                    <p className="caro-text caro-para">Stand with priests and empower them through prayer. Join today to strengthen those, shepherding God's flock.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Navigation Buttons */}
                    <button
                        style={{display:"none"}}
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="prev"
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </button>
                    <button
                        style={{display:"none"}}
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next"
                        ref={homeButtonRef}
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </button>
                </div>

                {/* Custom Progress Bar */}
                <div className="carousel-progress-bar">
                    <div className="carousel-progress"></div>
                </div>
            </section>
            <section style={{overflow: "hidden"}} className="Purpose-container">
                <div style={{zIndex:"-1"}} data-aos="fade-left">
                    <h4 className="Purpose-h4">Set The World Ablaze With Prayer And Purpose</h4>
                    <h5 className="Purpose-h5">As Christ declared in Luke 12:49:  </h5>
                    <h6 className="Purpose-h6">"I have come to set the earth on fire, and how I wish it were already blazing!"</h6>
                    <div className="purpose-div">
                        <p>Welcome to Luke 12:49, a global mission dedicated to transforming the world through the unparalleled power of prayer. At the heart of this ministry lies a divine calling to uplift and fortify priests, the frontline warriors of Christ, who stand as spiritual beacons in a world increasingly overshadowed by darkness. In this critical hour of history, where the battle between good and evil reaches unprecedented intensity, your prayers become a shield of grace and a weapon of hope. This is more than a mission; it is a heavenly mandate to unite believers in a sacred cause, empowering priests to lead with courage, ignite faith, and bring the light of Christ to even the darkest corners of the world.</p>
                    </div>
                </div>
            </section>
            <section className="gif-container">
                <div>
                    {/* <img data-aos="flip-left" className="img-fluid" src={`${process.env.PUBLIC_URL}/images/gif/home-priest.gif`} alt="Priest Gif" /> */}
                    <video data-aos="flip-left" className="img-fluid" src={`${process.env.PUBLIC_URL}/images/priestVideo.mp4`} controls loop/>
                </div>
            </section>
            <section style={{overflow: "hidden"}} className="d-flex justify-content-center p-5">
                <div className="what-container">
                    <div data-aos="fade-right">
                        <h5 className="what-h5">What We Do</h5>
                        <p className="what-para">Luke 12:49 is a platform where intercessors from around the world unite to pray for priests. It’s a sacred mission where believers respond to the divine call to be part of a movement that uplifts the Church’s shepherds, fortifies their ministry, and brings grace to families, communities, and nations.</p>
                    </div>
                    <div data-aos="fade-left">
                        <h5 className="what-h5">Here’s how it works:</h5>
                        <ol className="what-list">
                            <li>Pray for Priests: Choose a priest to spiritually adopt or pray for priests in general. Your prayers become a shield and a source of strength for them in their sacred mission.</li>
                            <li>Submit and Respond to Prayer Requests: Priests can share specific intentions or needs, allowing intercessors to offer prayers tailored to their challenges.</li>
                            <li>Join a Global Movement: Become part of a divine revolution where your sacrifices and prayers create ripples of grace that touch countless lives.</li>
                        </ol>
                    </div>
                    <div data-aos="fade-right">
                        <h5 className="what-h5">Why It Matters</h5>
                        <p className="what-list">This ministry exists at a unique time in history. While evil seeks to overwhelm, grace abounds all the more. Priests face immense challenges, both seen and unseen, as they guide God’s people. Your prayers can:</p>
                        <ul className="what-list">
                            <li>Strengthen their resolve.</li>
                            <li>Support their ministry.</li>
                            <li>Bring transformation to souls, families, and nations.</li>
                        </ul>
                        <p className="what-para">By spiritually adopting a priest, you join a heavenly mission, where your prayers and sacrifices become spiritual ammunition in the fight for souls. Though unseen in this life, your efforts will echo in eternity, celebrated by the Church Triumphant and rewarded by God.</p>
                    </div>
                    <div data-aos="fade-left">
                        <h5 className="what-h5">You Have Been Called </h5>
                        <p className="what-para">The fact that you are here means this is no coincidence. God has chosen you to stand in the gap, to be a light in the darkness, and to uplift the shepherds He has appointed. This platform is your opportunity to answer that call, to become a beacon of hope and a vessel of grace.</p>
                    </div>
                    <div data-aos="fade-right">
                        <h5 className="what-h5">How You Can Get Involved</h5>
                        <ul className="what-list">
                            <li>Pray Daily: Commit to daily prayer for priests, knowing your intercession fuels their mission.</li>
                            <li>Spread the Word: Encourage others to join this movement and strengthen the Church through collective prayer.</li>
                            <li>Support Our Mission: Together, let’s ensure every priest has the spiritual support they need to carry the light of Christ into the world.</li>
                        </ul>
                    </div>
                    <div data-aos="fade-left">
                        <p className="what-spl-para">The fire of faith has been lit. Now, it’s your time to help it burn brighter. Join us at Luke 12:49 and be part of a movement that changes lives, uplifts priests, and transforms the world through the power of prayer.</p>
                    </div>
                    <h6 data-aos="fade-right" className="what-h6">This is your mission. Will you answer the call?</h6>
                </div>
            </section>
        </div>
    );
};

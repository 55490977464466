import './CSS/Terms.css';

export const TermsAndCondition = () => {
    return (
        <div className='d-flex justify-content-center'>
            <div className='w-75' style={{textAlign: "justify"}}>
                <div className="text-center mt-5">
                    <h3 className='term-h3'>Terms and Conditions for [Luke1249.org]</h3>
                </div>
                <div className='mt-3'>
                    <p className='term-para'>Welcome to [luke1249.org (“we,” “our,” or “us”), a platform dedicated to [provide brief description of religious mission or purpose, e.g., spreading faith, providing resources for spiritual growth, etc.]. By accessing or using our website [URL] (https://luke1249.org ), you agree to comply with and be bound by these Terms and Conditions, as well as our Privacy Policy, which governs how we collect, use, and protect your personal data. If you do not agree to these terms, please refrain from using the Site.</p>
                    <ol>
                        <li className='term-h3'>Acceptance of Terms</li>
                        <p className='term-para'>By accessing or using the Site, you agree to comply with these Terms and Conditions. We reserve the right to modify, update, or change these terms at any time, and such changes will be effective immediately upon posting on this page. You are responsible for reviewing these Terms periodically for updates.</p>
                        <li className='term-h3'>Use of the Site</li>
                        <p className='term-para'>You may use the Site only for lawful purposes and in accordance with these Terms. You agree not to:</p>
                        <ul className='term-list'>
                            <li>Use the Site in any manner that could damage, disable, or impair the functionality of the Site or interfere with other users' enjoyment.</li>
                            <li>Upload, post, or transmit any content that is unlawful, defamatory, offensive, obscene, or contrary to the values of the religious community represented on the Site.</li>
                            <li>Use the Site to promote discriminatory, harmful, or illegal conduct.</li>
                            <li>Engage in activities that violate any local, state, national, or international law, or the rights of others.</li>
                        </ul>
                        <li className='term-h3'>Religious and Community Guidelines</li>
                        <p className='term-para'>Our Site serves as a platform for religious reflection, education, and community. All users are expected to uphold the values of respect, kindness, and understanding, as well as to engage in discussions and activities that align with our faith-based mission.</p>
                        <ul className='term-list'>
                            <li><b>Respectful Communication:</b>Please engage in discussions respectfully, showing consideration for others' beliefs and views.</li>
                            <li><b>Content Guidelines:</b>Any content that violates the principles of our faith or promotes divisiveness, hate speech, or harmful actions will be removed, and users may face suspension or termination of their accounts.</li>
                        </ul>
                        <li className='term-h3'>Account Registration</li>
                        <p className='term-para'>In order to access certain features of the Site, you may need to register for an account. When registering, you agree to provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
                        <li className='term-h3'>User-Generated Content</li>
                        <p className='term-para'>You may have the ability to post, upload, or share content on the Site, such as comments, articles, images, or videos (“User Content”). By submitting User Content, you grant us a non-exclusive, royalty-free, worldwide license to use, display, and distribute such content in connection with the Site’s operation and mission.</p>
                        <ul className='term-list'>
                            <li><b>Ownership:</b>You retain ownership of your User Content, but you grant us the right to use it as described above.</li>
                            <li><b>Prohibited Content:</b>You agree not to post content that violates copyright laws, intellectual property rights, or is otherwise illegal or harmful.</li>
                        </ul>
                        <li className='term-h3'>Privacy and Data Protection</li>
                        <p className='term-para'>Your use of the Site is governed by our [Privacy Policy], which explains how we collect, use, and protect your personal data. By using the Site, you consent to the data practices described in the Privacy Policy.</p>
                        <li className='term-h3'>Copyright and Intellectual Property</li>
                        <p className='term-para'>All content on the Site, including text, images, logos, and videos, is the property of luke1249.org or its licensors and is protected by copyright and intellectual property laws. You may not reproduce, distribute, or otherwise use any content from the Site without prior written consent, except as otherwise provided in these Terms.</p>
                        <li className='term-h3'>Disclaimers</li>
                        <ul className='term-list'>
                            <li><b>Religious Content:</b>While the content provided on the Site is intended to be aligned with [specific religious tradition, e.g., Christianity, Islam, etc.], it is not a substitute for personal religious guidance or professional counseling.</li>
                            <li><b>Accuracy:</b>We strive to provide accurate and reliable information, but we make no guarantees regarding the completeness or accuracy of the content on the Site. Use the Site at your own discretion and consult with relevant religious or community leaders for further guidance.</li>
                            <li><b>Third-Party Links:</b>The Site may contain links to third-party websites for informational purposes. We are not responsible for the content or practices of these external sites.</li>
                        </ul>
                        <li className='term-h3'>Termination of Access</li>
                        <p className='term-para'>We reserve the right to suspend or terminate your access to the Site, without notice, if you violate any of these Terms or engage in behavior that we deem inappropriate or harmful to the community.</p>
                        <li className='term-h3'>Limitation of Liability</li>
                        <p className='term-para'>To the maximum extent permitted by law, [Website Name] and its affiliates are not liable for any indirect, incidental, special, or consequential damages arising from your use or inability to use the Site, including, but not limited to, any religious or spiritual interpretations, advice, or guidance obtained from the Site.</p>
                        <li className='term-h3'>Governing Law</li>
                        <p className='term-para'>These Terms and Conditions are governed by the laws of [your country/state]. Any disputes arising from or related to these Terms will be resolved in the appropriate courts of [jurisdiction].</p>
                        <li className='term-h3'>Contact Us</li>
                        <p className='term-para'>If you have any questions about these Terms and Conditions or need assistance, please contact us at [contact email or phone number].</p>
                    </ol>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../API_PATH';
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import "./CSS/Intercessors.css";
import * as XLSX from 'xlsx';


export const Intercessors = () => {

    const [peopleData, setPeopleData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [dioceses, setDioceses] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDiocese, setSelectedDiocese] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [searchName, setSearchName] = useState("");
    const [searchContact, setSearchContact] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 9;

    useEffect(() => {
        axios.get(`${API_PATH}/pray_for_priest/get_people_details.php`)
                .then(response => {
                    setPeopleData(response.data);
                    setFilteredData(response.data);
                })
                .catch(error => console.error("Error fetching people details:", error));
        }, []);

    useEffect(() => {
        axios.get(`${API_PATH}/pray_for_priest/location_people.php?countries=true`)
            .then(response => {
                setCountries(response.data.map(country => ({
                    value: country.id,
                    label: country.name
                })));
            })
            .catch(error => console.error("Error fetching countries:", error));
      }, []);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        setSelectedState(null);
        setSelectedDiocese(null);
        setSelectedCity(null)
        filterData({ country: selectedOption?.label });
        // Fetch states based on selected country
        axios.get(`${API_PATH}/pray_for_priest/location_people.php?states=true&country_id=${selectedOption.value}`)
            .then(response => {
                setStates(response.data.map(state => ({
                    value: state.id,
                    label: state.name
                })));
            })
            .catch(error => console.error("Error fetching states:", error));
    };
  
    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedDiocese(null);
        setSelectedCity(null)
        filterData({ state: selectedOption?.label });
        // Fetch dioceses based on selected state
        axios.get(`${API_PATH}/pray_for_priest/location_people.php?dioceses=true&state_id=${selectedOption.value}`)
            .then(response => {
                setDioceses(response.data.map(diocese => ({
                    value: diocese.id,
                    label: diocese.name
                })));
            })
            .catch(error => console.error("Error fetching dioceses:", error));
    };
  
    const handleDioceseChange = (selectedOption) => {
        setSelectedDiocese(selectedOption);
        setSelectedCity(null)
        filterData({ diocese: selectedOption?.label });
        // Fetch cities based on selected diocese
        axios.get(`${API_PATH}/pray_for_priest/location_people.php?cities=true&diocese_id=${selectedOption.value}`)
            .then(response => {
                setCities(response.data.map(city => ({
                    value: city.id,
                    label: city.name
                })));
            })
            .catch(error => console.error("Error fetching cities:", error));
    };
  
    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption)
        filterData({ city: selectedOption?.label });
    }

    const customStyles = {
        control: (provided) => ({
          ...provided,
          border: 'none', 
          borderBottom: '2px solid #1F5258',
          borderRadius: 0,
          paddingBottom: '5px',
          cursor: "pointer",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#1F5258', 
          fontWeight: 'bold',
          opacity: '0.7'
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: '#1F5258', 
          padding: 4,
        }),
      };


      const filterData = (filters) => {
        let filtered = peopleData;

        if (filters.name) {
            filtered = filtered.filter(person =>
                person.people_name.toLowerCase().includes(filters.name.toLowerCase())
            );
        }
        if (filters.contact) {
            filtered = filtered.filter(person =>
                person.contact_number.toLowerCase().includes(filters.contact.toLowerCase())
            );
        }
        if (filters.country) {
            filtered = filtered.filter(person => person.country === filters.country);
        }
        if (filters.state) {
            filtered = filtered.filter(person => person.state === filters.state);
        }
        if (filters.diocese) {
            filtered = filtered.filter(person => person.diocese === filters.diocese);
        }
        if (filters.city) {
            filtered = filtered.filter(person => person.city === filters.city);
        }

        setFilteredData(filtered);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const visibleData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const paginationButtons = () => {
        const buttons = [];
        const start = Math.max(1, currentPage - 2);
        const end = Math.min(totalPages, start + 4);

        for (let i = start; i <= end; i++) {
            buttons.push(
                <button
                    key={i}
                    className={`success-btn ${i === currentPage ? "active" : ""}`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }

        return buttons;
    };

    const handleSearchChange = (e) => {
        setSearchName(e.target.value);
        filterData({ name: e.target.value });
    };

    const handleSearchContact = (e) => {
        setSearchContact(e.target.value);
        filterData({ contact: e.target.value });
    }

    const handleDelete = (id) => {
        axios.delete(`${API_PATH}/pray_for_priest/delete_person.php`, {
            data: {
                id: id
            }
        })
        .then(response => {
            if (response.data.status === 'success') {
                // Handle success, e.g., remove the deleted person from the UI
                setFilteredData(prevData => prevData.filter(person => person.id !== id));
            } else {
                alert('Failed to delete person');
            }
        })
        .catch(error => {
            console.error('Error deleting person:', error);
            alert('An error occurred while deleting the person');
        });
    };

    const handleDownload = () => {
        if (peopleData.length === 0) {
            alert("No data available to download.");
            return;
        }
    
        // Prepare data for Excel
        const worksheet = XLSX.utils.json_to_sheet(peopleData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Intercessors");
    
        // Export to Excel file
        XLSX.writeFile(workbook, "IntercessorsData.xlsx");
    };


    return (
        <div>
            <div className='filtering-intercessors'>
                <input className='input-intercessors' type="text" value={searchName} onChange={handleSearchChange} placeholder="Intercessors Name"/><br />
                <input className='input-intercessors' type="text" value={searchContact} onChange={handleSearchContact} placeholder="Contact Number"/>
                <Select placeholder="Country" options={countries} value={selectedCountry} onChange={handleCountryChange} isSearchable styles={customStyles}/>
                <Select placeholder="State" options={states} value={selectedState} onChange={handleStateChange} isSearchable styles={customStyles}/>
                <Select placeholder="Dioceses" options={dioceses} value={selectedDiocese} onChange={handleDioceseChange} isSearchable styles={customStyles}/>
                <Select placeholder="Cities" options={cities} value={selectedCity} onChange={handleCityChange} isSearchable styles={customStyles}/>
            </div>
            <div>
                <div className='table-container'>
                    <table className='w-100'>
                        <thead className='text-center'>
                            <tr className='table-tr'>
                                <th className='table-th'>Intercessors Name</th>
                                <th className='table-th'>Contact Number</th>
                                <th className='table-th'>Country</th>
                                <th className='table-th'>State</th>
                                <th className='table-th'>City</th>
                                <th className='table-action'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visibleData.map(person => (
                            <tr  key={person.id}>
                                <td className='table-td'><span className='tooltip-span'>{person.people_name}</span>{person.people_name}</td>
                                <td className='table-td'><span className='tooltip-span'>{person.contact_number} </span>{person.contact_number}</td>
                                <td className='table-td'><span className='tooltip-span'>{person.country}</span>{person.country}</td>
                                <td className='table-td'><span className='tooltip-span'>{person.state}</span>{person.state}</td>
                                <td className='table-td'><span className='tooltip-span'>{person.city}</span>{person.city}</td>
                                <td className='text-center table-delete'>
                                    <button className='danger-btn' onClick={() => handleDelete(person.id)}>Delete</button>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='d-flex justify-content-between'>
                    <div className="pagination">
                        <button
                            className="success-btn"
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            <FontAwesomeIcon icon={faAnglesLeft} />
                            Previous
                        </button>
                        {paginationButtons()}
                        <button
                            className="success-btn"
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                            <FontAwesomeIcon icon={faAnglesRight} />
                        </button>
                    </div>
                    <div className="pagination">
                        <button onClick={handleDownload} className="success-btn">Download</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./CSS/Footer.css";

export const Footer = () => {

    const navigate = useNavigate();

    const handlePrayerPriest = () => {
        navigate('/people');
    };

    const handleRequest = () => {
        navigate('/priest');
    }
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="footer-bg"></div>
            <div className="footer-content">
                <div className='w-100'>
                    <div className='d-flex justify-content-around align-items-center'>
                        <div>
                            <p className='foot-abouts'>
                                <b className='foot-about' onClick={() => window.scrollTo(0, 0)} ><NavLink to="/aboutus">About Us</NavLink></b> |
                                <b className='foot-mis' onClick={() => window.scrollTo(0, 0)} ><NavLink to="/mission">Our Mission</NavLink></b> |
                                <b className='foot-cont' onClick={() => window.scrollTo(0, 0)} ><NavLink to="/contact_us">Contact Us</NavLink></b>
                            </p>
                        </div>
                        <div className='d-flex justify-content-evenly align-items-center footer-nav'>
                            <div>
                                <p><a className='donate' href="https://saintmichaelusa.org/make-a-donation/" target="_blank" rel="noopener noreferrer" aria-label="Donation"><b>Donate</b></a></p>
                                <p onClick={handlePrayerPriest}><b>Join Our Community</b></p>
                            </div>
                            <div>
                                <p onClick={handlePrayerPriest}><b>Daily Prayers for Priests</b></p>
                                <p onClick={handleRequest}><b>Prayer Requests</b></p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-evenly align-items-center mt-3'>
                        <div className='line-div'></div>
                        <div>
                            <i><FacebookIcon className='icon-social'/></i>
                            <i><InstagramIcon className='icon-social'/></i>
                            <i><LinkedInIcon className='icon-social'/></i>
                            <i><TwitterIcon className='icon-social'/></i>
                            <i> <YouTubeIcon className='icon-social'/></i>
                        </div>
                        <div className='line-div'></div>
                    </div>
                    <div className='text-center mt-3'>
                        <p>&copy; Copyright   2024 Luke 12-49</p>
                        <p className='foot-abouts'>
                            <b className='foot-priv'><NavLink to="/privacy_policy" onClick={() => window.scrollTo(0, 0)} >Privacy Policy</NavLink></b> |
                            <b className='foot-faq'><NavLink to="/faqs" onClick={() => window.scrollTo(0, 0)} >FAQs</NavLink></b> |
                            <b className='foot-term'><NavLink to="/terms_and_condition" onClick={() => window.scrollTo(0, 0)} >Terms and Conditions </NavLink></b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
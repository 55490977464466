import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faSortDown, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { API_PATH } from '../API_PATH';
import "./CSS/PriestHome.css";
import { PrayerRequest } from './PrayerRequest';
import { PriestPrayList } from './PriestPrayList';
import { PriestProfile } from './PriestProfile';

export const Home = () => {

    const navigate = useNavigate();
    const [image, setImage] = useState(`${process.env.PUBLIC_URL}/images/dp.png`);
    const [email, setEmail] = useState('');
    const [isEmailReadOnly, setIsEmailReadOnly] = useState(false);
    const [sub, setSub] = useState('');
    const [priestName, setPriestName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDiocese, setSelectedDiocese] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [showWelcome, setShowWelcome] = useState(false);
    const [prefix, setPrefix] = useState('Title');

    // Changes based on thomas
    const [inputs, setInputs] = useState({
        option1: '',
        option2: '',
        option3: '',
        option4: ''
    });

    const [showInput, setShowInput] = useState({
        input1: false,
        input2: false,
        input3: false,
        input4: false
    });

    const [options, setOptions] = useState([]); 
    const [stateOptions, setStateOptions] = useState([]); 
    const [dioceseOptions, setDioceseOptions] = useState([]); 
    const [cityOptions, setCityOptions] = useState([]);
    const [selectedCountryId, setSelectedCountryId] = useState(null); 
    const [selectedStateId, setSelectedStateId] = useState(null); 
    const [selectedDioceseId, setSelectedDioceseId] = useState(null);
    const [selectedCityId, setSelectedCityId] = useState(null);

    useEffect(() => {
        const storedEmail = sessionStorage.getItem("userEmail");
        const userSub = sessionStorage.getItem('userSub');

        if (!storedEmail && !userSub) {
            navigate('/priest');
        }

        if (storedEmail) {
            setEmail(storedEmail);
            setIsEmailReadOnly(true);
        }

        if (userSub) {
            setSub(userSub);
        }

        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${API_PATH}/pray_for_priest/country.php`);
                const countries = response.data.map(country => ({
                    value: country.id,
                    label: country.name,
                }));
                setOptions(countries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, [navigate]);

    useEffect(() => {
        const storedEmail = sessionStorage.getItem("userEmail");
        const storedSub = sessionStorage.getItem("userSub");

        // Fetch all data from `priests_detais` table
        axios.get(`${API_PATH}/pray_for_priest/get_priest_details.php`)
            .then(response => {

                // Check session storage against the fetched data
                const matchedPriest = response.data.find(priest => {
                    return (storedEmail && priest.email === storedEmail) ||
                           (storedSub && priest.sub === storedSub);
                });

                if (matchedPriest) {
                    setShowWelcome(true);
                    setEmail(matchedPriest.email);
                    setSub(matchedPriest.sub);
                } else {
                    setShowWelcome(false);
                }
            })
            .catch(error => console.error("Error fetching priest details:", error));
    }, []);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type.split('/')[1]; // Get the file type (jpg, png, etc.)
            if (fileType === 'jpeg' || fileType === 'png') {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImage(reader.result); // Set the selected image as the profile picture
                };
                reader.readAsDataURL(file); // Read the file as a data URL
            } else {
                alert("Please select a valid image file (JPEG or PNG).");
            }
        }
    };

    useEffect(() => {
        if (selectedCountryId) {
            const fetchStates = async () => {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/state.php?country_id=${selectedCountryId}`);
                    const states = response.data.map(state => ({
                        value: state.id,
                        label: state.name,
                    }));
                    setStateOptions(states);
                } catch (error) {
                    console.error('Error fetching states:', error);
                }
            };
            fetchStates();
        }
    }, [selectedCountryId]);

    // Fetch dioceses based on selected state
    useEffect(() => {
        if (selectedStateId) {
            const fetchDioceses = async () => {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/diocese.php?state_id=${selectedStateId}`);
                    const dioceses = response.data.map(diocese => ({
                        value: diocese.id,
                        label: diocese.name,
                    }));
                    setDioceseOptions(dioceses);
                } catch (error) {
                    console.error('Error fetching dioceses:', error);
                }
            };
            fetchDioceses();
        }
    }, [selectedStateId]);

    useEffect(() => {
        if (selectedDioceseId) {
            const fetchCities = async () => {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/city.php?diocese_id=${selectedDioceseId}`);
                    const cities = response.data.map(city => ({
                        value: city.id,
                        label: city.name,
                    }));
                    setCityOptions(cities);
                } catch (error) {
                    console.error('Error fetching cities:', error);
                }
            };
            fetchCities();
        }
    }, [selectedDioceseId]);

    const handleSelectChange = (selectedOption, inputType) => {

        if (selectedOption.value === 'Other') {
            setShowInput({ ...showInput, [inputType]: true });
            return;
        }
    
        setInputs({ ...inputs, [inputType]: selectedOption.label });
    
        // Handle other inputType changes
        if (inputType === 'input1') {  // Country
            setInputs({
                ...inputs,
                option1: selectedOption.label,
                option2: null,
                option3: null,
                option4: null,
                option5: null,
            });
            setSelectedCountry(selectedOption.label)
            setSelectedCountryId(selectedOption.value);
            setSelectedStateId(null);
            setStateOptions([]);
            setDioceseOptions([]);
            setCityOptions([]);
        } else if (inputType === 'input2') {  // State
            setInputs({
                ...inputs,
                option2: selectedOption.label,
                option3: null,
                option4: null,
            });
            setSelectedState(selectedOption.label)
            setSelectedStateId(selectedOption.value);
            setDioceseOptions([]);
            setCityOptions([]);
        } else if (inputType === 'input3') {  // Diocese
            setInputs({
                ...inputs,
                option3: selectedOption.label,
                option4: null,
            });
            setSelectedDiocese(selectedOption.label)
            setSelectedDioceseId(selectedOption.value);
            setCityOptions([]);
        } else if (inputType === 'input4') {  // City
            setInputs({
                ...inputs,
                option4: selectedOption.label,
            });
            setSelectedCity(selectedOption.label)
            setSelectedCityId(selectedOption.value);
        } else if (inputType === 'input5') {  // Church
            setInputs({
                ...inputs,
                option5: selectedOption.label,
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
    };

    const handleSave = async (optionKey) => {
        const value = inputs[optionKey];
    
        if (!value.trim()) {
            alert(`${optionKey.replace('option', 'Option ')} name cannot be empty.`);
            return;
        }
    
        let apiUrl;
    
        if (optionKey === 'option1') {
            apiUrl = `${API_PATH}/pray_for_priest/country.php`;
        } else if (optionKey === 'option2') {
            apiUrl = `${API_PATH}/pray_for_priest/state.php`;
            if (!selectedCountryId) {
                alert("Please select a country before saving the state.");
                return;
            }
        } else if (optionKey === 'option3') {
            apiUrl = `${API_PATH}/pray_for_priest/diocese.php`;
            if (!selectedStateId) {
                alert("Please select a state before saving the diocese.");
                return;
            }
        } else if (optionKey === 'option4') {
            apiUrl = `${API_PATH}/pray_for_priest/city.php`; 
            if (!selectedDioceseId) {
                alert("Please select a diocese before saving the city.");
                return;
            }
        } else {
            apiUrl = `${API_PATH}/pray_for_priest/church.php`;
            if (!selectedCityId) {
                alert("Please select a city before saving the church.");
                return;
            }
        }
    
        try {
            await axios.post(apiUrl, {
                name: value,
                country_id: optionKey === 'option2' ? selectedCountryId : undefined, // Pass country_id for state only
                state_id: optionKey === 'option3' ? selectedStateId : undefined, // Pass state_id for diocese only
                diocese_id: optionKey === 'option4' ? selectedDioceseId : undefined,
            });
    
            // Fetch updated countries, states, or dioceses after saving
            if (optionKey === 'option1') {
                const response = await axios.get(`${API_PATH}/pray_for_priest/country.php`);
                const countries = response.data.map(country => ({
                    value: country.id,
                    label: country.name,
                }));
                setOptions(countries);
            } else if (optionKey === 'option2') {
                const response = await axios.get(`${API_PATH}/pray_for_priest/state.php?country_id=${selectedCountryId}`);
                const states = response.data.map(state => ({
                    value: state.id,
                    label: state.name,
                }));
                setStateOptions(states);
            } else if (optionKey === 'option3') {
                const response = await axios.get(`${API_PATH}/pray_for_priest/diocese.php?state_id=${selectedStateId}`);
                const dioceses = response.data.map(diocese => ({
                    value: diocese.id,
                    label: diocese.name,
                }));
                setDioceseOptions(dioceses);
            } else if (optionKey === 'option4') {
                const response = await axios.get(`${API_PATH}/pray_for_priest/city.php?diocese_id=${selectedDioceseId}`);
                const cities = response.data.map(city => ({
                    value: city.id,
                    label: city.name,
                }));
                setCityOptions(cities);
            }
    
            setShowInput({ ...showInput, [optionKey]: false });
            setInputs({ ...inputs, [optionKey]: '' });
        } catch (error) {
            console.error('Error saving:', error);
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: 'none', 
            borderBottom: '2px solid #1F5258',
            borderRadius: 0,
            paddingBottom: '5px',
            cursor: "pointer",
            paddingLeft: '10px',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#1F5258', 
            fontWeight: 'bold',
            opacity: '0.7'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#1F5258', 
            padding: 4,
        }),
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        const missingFields = [];

        // Check for missing fields and add them to the array
        if (prefix === "Title") missingFields.push("Select the Title")
        if (!email) missingFields.push("Email");
        if (!priestName) missingFields.push("First Name");
        if (!lastName) missingFields.push("Last Name");
        if (!contactNumber) missingFields.push("Contact Number");
        if (!selectedCountry) missingFields.push("Country");
        if (!selectedState) missingFields.push("State");
        if (!selectedDiocese) missingFields.push("Diocese");
        if (!selectedCity) missingFields.push("City");
        if (!image || image === `${process.env.PUBLIC_URL}/images/dp.png`) missingFields.push("Image");

        // If there are missing fields, show them in the alert
        if (missingFields.length > 0) {
            alert("Please fill in the following required fields: " + missingFields.join(", "));
            return;
        }

        const fullPeopleName = `${prefix}. ${priestName}`;

        const formData = new FormData();
        formData.append("email", email);
        formData.append("sub", sub);
        formData.append("priestName", fullPeopleName);
        formData.append("lastName", lastName);
        formData.append("contactNumber", contactNumber);
        formData.append("country", selectedCountry);
        formData.append("state", selectedState);
        formData.append("diocese", selectedDiocese);
        formData.append("city", selectedCity);
        formData.append("image", image);

        axios.post(`${API_PATH}/pray_for_priest/insert_priest.php`, formData)
            .then(response => {
                console.log(response.data);
                alert("Priest information saved successfully!");
                setPriestName("");
                setLastName("");
                setEmail("");
                setContactNumber("");
                setSelectedCountry(null);
                setSelectedState(null);
                setSelectedDiocese(null);
                setSelectedCity(null);
                setShowWelcome(true)
                setImage(`${process.env.PUBLIC_URL}/images/dp.png`);
            })
            .catch(error => console.error("Error submitting form:", error));
    };

    const handlePrefixChange = (e) => {
        setPrefix(e.target.value);
      };

    return (
        <div>
            {showWelcome ? (
                <div className='welcome'>
                    <PriestHome/>
                </div>
            ) : (
            <div className="container priests-form">
                <div className="row align-items-center priest-detail">
                    <div className="col-md-2 text-center text-md-start p-4">
                        <img src={`${process.env.PUBLIC_URL}/images/luke-logo.png`} alt="Logo" className="img-fluid" />
                    </div>
                    <div className="col-12 col-md-8 d-flex justify-content-center">
                        <div className="priest-form">
                            <form onSubmit={handleFormSubmit} >
                                <div className="priest-dp">
                                    <img className="img-fluid dp-img" src={image} alt="Profile" />
                                    <button type="button" className="img-icon" onClick={() => document.getElementById('fileInput').click()}><FontAwesomeIcon icon={faImage} /></button>
                                    <input type="file" style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={handleFileChange} id="fileInput" />
                                </div>
                                <div className="form-div-input d-flex">
                                    <select className='prefix' value={prefix} onChange={handlePrefixChange}>
                                        <option value="Title">Select Title</option>
                                        <option value="Bishop">Bishop</option>
                                        <option value="Pope">Pope</option>
                                        <option value="Archbishop">Archbishop</option>
                                        <option value="Cardinal">Cardinal</option>
                                        <option value="Canon law">Canon law</option>
                                        <option value="Chancellor">Chancellor</option>
                                        <option value="Metropolitan archbishop">Metropolitan archbishop</option>
                                        <option value="Priest">Priest</option>
                                        <option value="Ordained deacon">Ordained deacon</option>
                                        <option value="Transitional diaconate">Transitional diaconate</option>
                                    </select>
                                    <input className="w-100 form-input" type="text" placeholder="First Name" value={priestName} onChange={(e) => setPriestName(e.target.value)} required /><br />
                                </div>
                                <div className="form-div-input">
                                    <input className="w-100 form-input" type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required /><br />
                                </div>
                                <div className="form-div-input">
                                    {isEmailReadOnly ? (
                                        <input className="w-100 form-input" type="email" placeholder="Email ID" value={email} readOnly />
                                    ) : (
                                        <input className="w-100 form-input" type="email" placeholder="Email ID" value={email} onChange={handleEmailChange}  required />
                                    )}
                                    <br />
                                </div>
                                <div className="form-div-input">
                                    <input className="w-100 form-input" type="number" placeholder="Contact Number" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} required /><br />    
                                </div>
                                <div>
                                    {!showInput.input1 ? (
                                        <div>
                                            <Select
                                                placeholder="Country"
                                                name="option1"
                                                value={inputs.option1 ? options.find(option => option.label === inputs.option1) : null}
                                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'input1')}
                                                options={[...options, { value: 'Other', label: 'Other' }]}
                                                isSearchable
                                                styles={customStyles}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="text"
                                                name="option1"
                                                value={inputs.option1}
                                                onChange={handleInputChange}
                                                placeholder="Enter country name"
                                                className='form-control'
                                            />
                                            <button className='butto' onClick={() => handleSave('option1')}>Save</button>
                                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input1: false })}>Cancel</button>
                                        </div>
                                    )}
                                </div>
                                {/* State Selection */}
                                <div>
                                    {!showInput.input2 ? (
                                        <div>
                                            <Select
                                                placeholder="State"
                                                name="option2"
                                                value={inputs.option2 ? stateOptions.find(option => option.label === inputs.option2) : null}
                                                onChange={(selectedOption) => {
                                                    handleSelectChange(selectedOption, 'input2');
                                                    setSelectedStateId(selectedOption.value); // Set the selected state ID
                                                }}
                                                options={[...stateOptions, { value: 'Other', label: 'Other' }]}
                                                isSearchable
                                                styles={customStyles}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="text"
                                                name="option2"
                                                value={inputs.option2}
                                                onChange={handleInputChange}
                                                placeholder="Enter state name"
                                                className='form-control'
                                            />
                                            <button className='butto' onClick={() => handleSave('option2')}>Save</button>
                                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input2: false })}>Cancel</button>
                                        </div>
                                    )}
                                </div>
                                {/* Diocese Selection */}
                                <div>
                                    {!showInput.input3 ? (
                                        <div>
                                            <Select
                                                placeholder="Diocese"
                                                name="option3"
                                                value={inputs.option3 ? dioceseOptions.find(option => option.label === inputs.option3) : null}
                                                onChange={(selectedOption) => {
                                                    handleSelectChange(selectedOption, 'input3');
                                                    setSelectedDioceseId(selectedOption.value); // Set the selected diocese ID
                                                }}
                                                options={[...dioceseOptions, { value: 'Other', label: 'Other' }]}
                                                isSearchable
                                                styles={customStyles}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="text"
                                                name="option3"
                                                value={inputs.option3}
                                                onChange={handleInputChange}
                                                placeholder="Enter diocese name"
                                                className='form-control'
                                            />
                                            <button className='butto' onClick={() => handleSave('option3')}>Save</button>
                                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input3: false })}>Cancel</button>
                                        </div>
                                    )}
                                </div>
                                {/* Cities Selection */}
                                <div>
                                    {!showInput.input4 ? (
                                        <div>
                                            <Select
                                                placeholder="City"
                                                name="option4"
                                                value={inputs.option4 ? cityOptions.find(option => option.label === inputs.option4) : null}
                                                onChange={(selectedOption) => {
                                                    handleSelectChange(selectedOption, 'input4');
                                                    setSelectedCityId(selectedOption.value); // Set the selected city ID
                                                }}
                                                options={[...cityOptions, { value: 'Other', label: 'Other' }]}
                                                isSearchable
                                                styles={customStyles}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="text"
                                                name="option4"
                                                value={inputs.option4}
                                                onChange={handleInputChange}
                                                placeholder="Enter city name"
                                                className='form-control'
                                            />
                                            <button className='butto' onClick={() => handleSave('option4')}>Save</button>
                                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input4: false })}>Cancel</button>
                                        </div>
                                    )}
                                </div>
                                <div className="text-center">
                                    <button className="form-btn mt-3" type="submit">SUBMIT</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
};


const PriestHome = () => {

    const [priestData, setPriestData] = useState([]);
    const [followData, setFollowData] = useState([]);
    const [allPeople, setAllPeople] = useState([]);
    const [matchedFollowers, setMatchedFollowers] = useState([]);
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('home');

    useEffect(() => {
        // Fetch follow data
        axios.get(`${API_PATH}/pray_for_priest/get_follow_priests.php`)
            .then(response => {
                setFollowData(response.data);
            })
            .catch(error => console.error("Error fetching follow data:", error));
    }, []);

    useEffect(() => {
        // Fetch all priests
        axios.get(`${API_PATH}/pray_for_priest/get_people_details.php`)
            .then(response => {
                setAllPeople(response.data);
            })
            .catch(error => console.error("Error fetching priests details:", error));
    }, []);

    useEffect(() => {
        const storedEmail = sessionStorage.getItem("userEmail");
        const storedSub = sessionStorage.getItem("userSub");

        // Fetch all data from `priests_detais` table
        axios.get(`${API_PATH}/pray_for_priest/get_priest_details.php`)
            .then(response => {

                // Check session storage against the fetched data
                const matchedPriest = response.data.find(priest => {
                    return (storedEmail && priest.email === storedEmail) ||
                           (storedSub && priest.sub === storedSub);
                });

                if (matchedPriest) {
                    setPriestData(matchedPriest);
                }
            })
            .catch(error => console.error("Error fetching priest details:", error));
    }, []);

    useEffect(() => {
        // Filter people who follow the current priest based on priest_id
        const matched = followData
            .filter(follow => follow.priest_id === priestData.priest_id) // Match based on priest_id
            .map(follow => {
                const person = allPeople.find(person => person.people_id === follow.people_id);
                return person ? {
                    profile:person.profile_image,
                    peopleName: person.people_name,
                    country: person.country,
                    state: person.state
                } : null;
            })
            .filter(match => match !== null); // Remove null entries
    
        setMatchedFollowers(matched);
    }, [followData, allPeople, priestData]);

    const handleLogout = () => {
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('userSub');
        navigate('/priest'); 
    };

    const renderSectionHandler = (section) => {
        setActiveSection(section);  
    };

    const renderSection = () => {
        switch (activeSection) {
            case 'home':
                return <div><PriestPrayList/></div>;
            case 'prayerRequest':
                return <div><PrayerRequest/></div>; 
            case 'profile':
                return <div><PriestProfile renderSectionHandler={renderSectionHandler}/></div>; 
            default:
                return null;
        }
    };


    return(
        <div>
            <div className="priest-home">
                <div className='priest-container'>
                    <div className="log-div" >
                        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/luke-logo.png`} alt="Logo" />
                    </div>
                    <div className='mt-5'>
                        <div className='priest-div' onClick={() => setActiveSection('home')}>
                            <h6 className={`priest-h6 ${activeSection === 'home' ? 'active' : ''}`}>Home</h6>
                        </div>
                        <div className='priest-div' onClick={() => setActiveSection('prayerRequest')}>
                            <h6 className={`priest-h6 ${activeSection === 'prayerRequest' ? 'active' : ''}`}>Prayer Request</h6>
                        </div>
                        <div className='priest-div' onClick={() => setActiveSection('profile')}>
                            <h6 className={`priest-h6 ${activeSection === 'profile' ? 'active' : ''}`}>Profile</h6>
                        </div>
                        <div className='priest-div'>
                            <h6 className='priest-h6' onClick={handleLogout}>Logout</h6>
                        </div>
                    </div>
                </div>
                <div className='render-section'>
                    {renderSection()}
                </div>
                <div>
                    <div className='right-profile'>
						<div className='right-image'>
							{/* <img className='img-fluid right-image-border' src={`${process.env.PUBLIC_URL}/images/sample.png`} alt="" /> */}
							<img className='right-image-border' src={`${API_PATH}/pray_for_priest/${priestData.profile_image}`} alt="" />
						</div>
						<div className='right-profile-value'>
							<p className='text mt-4'>{priestData.priest_name}</p>
							<p className='text mt-3' style={{cursor: "pointer"}}><FontAwesomeIcon icon={faSortDown} /></p>
						</div>
					</div>
					<div className='p-2'>
						<div className='search-profile'>
							<span className='search-icon' ><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
							<input className='search-bar' type="text" placeholder='Search People'/>
						</div>
					</div>
					<p className='text mt-3'>Fellow's you</p>
					<div className='text-center'>
                        <div className='follow-list p-2'>
                            {matchedFollowers.map((follower, index) => (
                                <div key={index} className='follow'>
                                    <div className='follow-img-div'>
                                        {/* <img className='img-fluid follow-img' src={`${process.env.PUBLIC_URL}/images/sample.png`} alt="" /> */}
                                        <img className='img-fluid follow-img' src={`${API_PATH}/pray_for_priest/${follower.profile}`} alt="Profile DP" />
                                    </div>
                                    <div className='follow-content text-start'>
                                        <div>
                                            <b className='follow-priest'>{follower.peopleName}</b><br />
                                            <i className='follow-loc'>{follower.country}</i><br />
                                            <i className='follow-loc'>{follower.state}</i>
                                        </div>
                                        <div className='follow-follow'>
                                            <b className='follow-follow-font'>Following</b>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
						<button className='follow-btn'>VIEW MORE</button>
					</div>
                </div>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from "react";
import './CSS/Admin.css';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import { API_PATH } from "../API_PATH";

export const Register = () => {
    const [showPassword, setShowPassword] = useState(false); 
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
    const [mobile, setMobile] = useState("");
    const [adminName, setAdminName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const [adminNameTaken, setAdminNameTaken] = useState(false);
    const navigate = useNavigate();

    const handleNav = () => {
        navigate("/admin");
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        const checkAdminName = async () => {
            if (adminName) {
                const response = await axios.post(`${API_PATH}/pray_for_priest/check_admin.php`, { admin_name: adminName });
                setAdminNameTaken(response.data.exists);
            } else {
                setAdminNameTaken(false);
            }
        };

        const delayDebounceFn = setTimeout(() => {
            checkAdminName();
        }, 2000); 

        return () => clearTimeout(delayDebounceFn);
    }, [adminName]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        const validationErrors = [];

        if (!adminName) {
            validationErrors.push("Admin name is required.");
        }

        if (!mobile) {
            validationErrors.push("Mobile number is required.");
        }

        if (!password) {
            validationErrors.push("Password is required.");
        } else {
            if (!/[A-Z]/.test(password)) {
                validationErrors.push("Password must have at least one uppercase letter.");
            }
            if (!/[\W_]/.test(password)) {
                validationErrors.push("Password must contain at least one special character.");
            }
            if (!/[0-9]/.test(password)) {
                validationErrors.push("Password must contain at least one number.");
            }
        }

        if (password !== confirmPassword) {
            validationErrors.push("Passwords do not match.");
        }

        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        }

        const data = {
            admin_name: adminName,
            mobile: mobile,
            password: password,
            confirm_password: confirmPassword
        };

        try {
            const response = await axios.post(`${API_PATH}/pray_for_priest/admin_register.php`, data);
            if (response.data.status === 'success') {
                alert('Admin successfully registered!');
                handleNav();
            } else {
                setErrors(response.data.messages || ['Failed to register admin.']);
            }
        } catch (error) {
            console.error('There was an error!', error);
            setErrors(['Failed to register admin.']);
        }
    };

    return (
        <div>
            <div className="background" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/gif/reg1.gif)` }}>
                <div className="container mt-5">
                    <div className="row justify-content-center align-items-center gap-2">
                        <div className="col-lg-4 col-md-12 rounded" style={{ color: "white" }}>
                            <div className="d-flex justify-content-center mt-2">
                                <h4 className="fs-2">Admin Registration</h4>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">Admin Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={adminName}
                                        onChange={(e) => setAdminName(e.target.value)}
                                    />
                                    {adminNameTaken && (
                                        <div className="text-danger" style={{ marginTop: '2px', fontSize: "12px" }}>
                                            Admin name is already taken.
                                        </div>
                                    )}
                                    {errors.find(err => err.includes("Admin name")) && (
                                        <div className="text-danger" style={{ marginTop: '2px', fontSize: "12px" }}>
                                            {errors.find(err => err.includes("Admin name"))}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Enter Mobile Number</label>
                                    <PhoneInput
                                        country={'in'}
                                        value={mobile}
                                        onChange={setMobile}
                                        inputClass="form-control" 
                                        inputStyle={{ width: "100%" }}
                                    />
                                    {errors.find(err => err.includes("Mobile number")) && (
                                        <div className="text-danger" style={{ marginTop: '2px', fontSize: "12px" }}>
                                            {errors.find(err => err.includes("Mobile number"))}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            style={{border:"none"}}
                                        />
                                        <span className="input-group-text" style={{backgroundColor: "white", border: "none"}}>
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}/>
                                        </span>
                                    </div>
                                    {errors.find(err => err.includes("Password")) && (
                                        <div className="text-danger" style={{ marginTop: '2px', fontSize: "12px" }}>
                                            {errors.find(err => err.includes("Password"))}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Confirm Password</label>
                                    <div className="input-group">
                                        <input
                                            type={showConfirmPassword ? "text" : "password"}
                                            className="form-control"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            style={{border:"none"}}
                                        />
                                        <span className="input-group-text" style={{backgroundColor: "white", border: "none"}}>
                                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} onClick={toggleConfirmPasswordVisibility} style={{ cursor: "pointer" }}/>
                                        </span>
                                    </div>
                                    {errors.find(err => err.includes("Passwords do not match")) && (
                                        <div className="text-danger" style={{ marginTop: '2px', fontSize: "12px" }}>
                                            {errors.find(err => err.includes("Passwords do not match"))}
                                        </div>
                                    )}
                                </div>
                                <button type="submit" className="button">Sign UP</button>
                                <div className="form-text text-end opacity-75 mb-3" style={{ color: "white" }}>
                                    or already Log in <br />
                                    <b onClick={handleNav} style={{ color: "rgb(255, 215, 0)", cursor: "pointer" }}>LOG IN</b>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-4 col-md-12 mb-4">
                            <div className="card bg-dark text-white border-0" style={{ borderRadius: "15% 2% 35% 2%" }}>
                                <img src={`${process.env.PUBLIC_URL}/images/mary.jpg`} style={{ borderRadius: "15% 2% 35% 2%" }} className="card-img" alt="Login Side" />
                                <div className="card-img-overlay d-flex justify-content-center align-items-center">
                                    <div>
                                        <h5 className="card-title text-center">Welcome Back</h5>
                                        <p className="card-text text-center opacity-50">
                                            This is a wider card with supporting text below as a natural lead-in to additional content.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../API_PATH';
import "./CSS/PrayerRequest.css";

export const PrayerRequest = () => {

    const [priestData, setPriestData] = useState([]);
    const [prayerText, setPrayerText] = useState("");
    const [prayerRequests, setPrayerRequests] = useState([]);
    const [prayerSupport, setPrayerSuport] = useState([])

    useEffect(() => {
        const storedEmail = sessionStorage.getItem("userEmail");
        const storedSub = sessionStorage.getItem("userSub");

        // Fetch all data from `priests_detais` table
        axios.get(`${API_PATH}/pray_for_priest/get_priest_details.php`)
            .then(response => {

                // Check session storage against the fetched data
                const matchedPriest = response.data.find(priest => {
                    return (storedEmail && priest.email === storedEmail) ||
                           (storedSub && priest.sub === storedSub);
                });

                if (matchedPriest) {
                    setPriestData(matchedPriest);
                }
            })
            .catch(error => console.error("Error fetching priest details:", error));
    }, []);

    useEffect(() => {
        // Fetch all prayer requests
        axios.get(`${API_PATH}/pray_for_priest/prayer_request.php`)
            .then(response => {
                const filteredRequests = response.data
                    .filter(request => request.priest_id === priestData.priest_id)
                    .sort((a, b) => new Date(b.posted_on) - new Date(a.posted_on)); // Sort by date and time in descending order

                setPrayerRequests(filteredRequests);
            })
            .catch(error => console.error("Error fetching prayer requests:", error));
    }, [priestData.priest_id]);

    const fetchPrayerRequests = () => {
        // Fetch all prayer requests
        axios.get(`${API_PATH}/pray_for_priest/prayer_request.php`)
            .then(response => {
                const filteredRequests = response.data
                    .filter(request => request.priest_id === priestData.priest_id)
                    .sort((a, b) => new Date(b.posted_on) - new Date(a.posted_on)); // Sort by date and time in descending order

                setPrayerRequests(filteredRequests);
            })
            .catch(error => console.error("Error fetching prayer requests:", error));
    };

    const handlePost = () => {
        if (prayerText.trim() === "") {
            alert("Please enter a prayer request.");
            return;
        }

        const now = new Date();
        const currentDateTime = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ` +
                                `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;


        const requestData = {
            priest_id: priestData.priest_id,
            prayerText: prayerText,
            posted_on: currentDateTime
        };

        // Send prayer request to backend
        axios.post(`${API_PATH}/pray_for_priest/prayer_request.php`, requestData)
            .then(response => {
                if (response.data.success) {
                    alert("Prayer request posted successfully!");
                    setPrayerText("");
                    fetchPrayerRequests();
                } else {
                    alert("Failed to post prayer request.");
                }
            })
            .catch(error => console.error("Error posting prayer request:", error));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };
        // const optionsTime = { hour: 'numeric', minute: '2-digit', hour12: true };
        return `${date.toLocaleDateString('en-GB', optionsDate)}`; // ${date.toLocaleTimeString('en-US', optionsTime)}
    };

    useEffect(() => {
        // Fetch prayer count data
        axios.get(`${API_PATH}/pray_for_priest/prayering_support.php`)
            .then(response => {
                setPrayerSuport(response.data);
            })
            .catch(error => console.error("Error fetching prayer count details:", error));
    }, []);

    const formatCount = (count) => {
        if (count >= 1000) {
            return (count / 1000).toFixed(1) + 'K'; 
        }
        return count;
    };
    
    
    return (
        <div className="PrayerRequest-container">
            <div className="request-container">
                <div className="prayer-text">
                    <textarea className="w-100 text-area" placeholder="Type your Prayer Request here" value={prayerText} onChange={(e) => setPrayerText(e.target.value)}></textarea>
                </div>
                <button className="post-btn" onClick={handlePost}>Post</button>
            </div>
            <div className="prayer-content">
                <div className="prayer-list" >
                    {prayerRequests.map((request, index) => {
                        // Calculate the total count of matching prayer_id in prayerSupport
                        const totalPrayerCount = prayerSupport.filter(support => support.prayer_id === request.prayer_id).length;

                        return (
                            <div key={index} className="prayer-div">
                                <div className="prayer-image-div">
                                    <img className="img-fluid prayer-img" src={`${API_PATH}/pray_for_priest/${priestData.profile_image}`} alt="Dp" />
                                </div>
                                <p>{request.prayer_text}</p>
                                <div className="prayer-count">
                                    <div>
                                        <b className="head-count">Total Prayers</b><br />
                                        <span className="head-value">{formatCount(totalPrayerCount)}</span>
                                    </div>
                                    <div>
                                        <b className="head-count">Posted On</b><br />
                                        <span className="head-value">{formatDate(request.posted_on)}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}
import "./CSS/Aboutus.css";
import React, { useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const Mission = () => {

    useEffect(() => {
        AOS.init();
      }, []);


    return (
        <div>
            <section className="priestHeader container-fluid d-flex align-items-center flex-column flex-md-row">
                <div className="about-text">
                    <p className="priest-h2">Mission</p>
                </div>
                <div className="text-center">
                    <img
                        className="img-fluid"
                        src={`${process.env.PUBLIC_URL}/images/mission-top.png`}
                        alt="Priest"
                    />
                </div>
            </section>
            <section style={{overflow: "hidden"}} className="mission-container">
                <div className="mission-text">
                    <div data-aos="fade-right" className="text-center mt-5">
                        <h3 className="abo-h3">Luke 12:49 – Empowering Faith, One Prayer at a Time</h3>
                    </div>
                    <div data-aos="fade-left" className="mt-5">
                        <p className="abo-para">At Luke 12:49, we are proud to be a part of the spiritual journey championed by the Saint Michael Center for the Blessed Virgin Mary. Our mission is grounded in the values of faith, community, and the belief that prayer can change lives. Through the powerful intercession of the Blessed Virgin Mary and St. Michael, we are dedicated to supporting priests in their sacred missions and uniting believers worldwide in a network of prayer.</p>
                    </div>
                    <div data-aos="fade-right" className="mt-4">
                        <h5 className="abo-h3">Our Mission in Action</h5>
                        <ol className="abo-list">
                            <li><b>Empowering Priests</b> – The priests, as shepherds of our faith, are at the heart of our mission. By connecting intercessors to priests in need of prayer, we ensure that these spiritual leaders receive the strength, guidance, and protection necessary to carry out their divine work. Just as the Saint Michael Center honors and supports the Church’s leaders, we believe that prayer plays a pivotal role in fortifying priests to serve their communities.</li>
                            <li><b>Building a Global Prayer Network</b> – Our platform goes beyond borders to unite believers in prayer. We believe in the power of intercession to bring about transformative change. By joining our mission, you become part of a global network of intercessors committed to lifting up priests and souls in prayer—echoing the work of the Saint Michael Center, which strives to lead people closer to faith through devotion and spiritual support.</li>
                        </ol>
                    </div>
                    <div data-aos="fade-left">
                        <p className="spl-miss">Our mission is to empower souls, strengthen the Church’s mission, and bring the transformative power of prayer to the forefront. With the intercession of the Blessed Virgin Mary and St. Michael, we can achieve this powerful work together.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}
import React, { useState } from 'react';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Home } from './Home';
import "./CSS/People.css";
import { Register } from './Register';
import { ResetPeoplePassword } from './ResetPeoplePassword';
import axios from 'axios';
import { API_PATH } from '../API_PATH';

export const People = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false); 
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLoginSuccess = (response) => {
        const userName = response.data.name;
        const userSub = response.data.sub;

        axios.post(`${API_PATH}/pray_for_priest/google_people.php`, {
            userName: userName,
            userSub: userSub
        })
        .then((response) => {
            if (response.data.success) {
                // Set sessionStorage
                sessionStorage.setItem('userName', userName);
                sessionStorage.setItem('userSub', userSub);
                navigate("/people/home");
                alert("Logged in with Google");
            } else {
                console.error("Error saving user data:", response.data.error);
            }
        })
        .catch((error) => {
            // Handle any errors in the request
            console.error("Error occurred while sending the data:", error);
        });
    };

    const handleLoginFailure = (error) => {
        console.log('Login Failed:', error);
    };

    const handleRegister = () => {
        navigate('/people/register')
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${API_PATH}/pray_for_priest/check_people.php`, {
                email: email,
                password: password,
            });
            console.log(res.data);
            if (res.data.success) {
                sessionStorage.setItem('userEmail', res.data.email);
                navigate("/people/home");
                setEmail("");
                setPassword("");
                setError("");
            } else {
                setError("Invalid email or password");
            }
        } catch (err) {
            setError("An error occurred. Please try again.");
        }
    };

    const handleForgotPassword = () => {
        setShowForgotPassword(true);
    };

    const handleBackForgot = () => {
        setShowForgotPassword(false);
    };

    return (
        <div>
            {location.pathname === "/people" && (
                <div 
                    className="bg-people" 
                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/people.jpg)` }}
                >
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className={`people-container glass-effect p-4 ${showForgotPassword ? "move-out" : "move-in"}`}>
                            <div className='text-center'>
                                <h3>Intercessors</h3>
                                <p style={{textDecoration:"underline"}}>Uplift Priests Through Prayer Here</p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <label className='form-label mt-2'>User Email</label>
                                <input 
                                    type="email" 
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label className='form-label'>Password</label>
                                <div className='input-group'>
                                    <input 
                                        type={showPassword ? "text" : "password"} 
                                        className="form-control"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span className="input-group-text" style={{backgroundColor: "white", border: "none"}}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={togglePasswordVisibility} style={{ cursor: "pointer", fontSize: "12px" }}/>
                                    </span>
                                </div>
                                {error && <p className="error-message mt-1" style={{ fontSize: "12px", color: "red" }}>{error}</p>}
                                <div className='d-flex' style={{width: "100%"}}>
                                    <label class="custom-checkbox">
                                        <input type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                    <div className='d-flex justify-content-between mt-3' style={{ width: "100%"}}>
                                        <p style={{fontSize: "12px", color: "#ffff"}}>Remember me!!</p>
                                        <p onClick={handleForgotPassword} style={{fontSize: "12px", color:"#3498db", cursor:"pointer"}}>Forgot password?</p>
                                    </div>
                                </div>
                                <button class="button-people" type="submit">
                                Login
                                <svg class="icon" viewBox="0 0 24 24" fill="currentColor">
                                    <path
                                    fill-rule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                    clip-rule="evenodd"
                                    ></path>
                                </svg>
                                </button>

                            </form>
                            <LoginSocialGoogle
                                client_id="742398674417-g0u59h5jp03mf5bbd1ept7puc8bej8ul.apps.googleusercontent.com"
                                onResolve={handleLoginSuccess}
                                onReject={handleLoginFailure}
                            >
                                <button className='ui-btn'>
                                    <span className='google-btn'>
                                        G - Login
                                    </span>
                                </button>
                            </LoginSocialGoogle>
                            <p className='mt-2 mb-0' style={{fontSize: "10px", textAlign: "center"}}>Don't have an account? <b onClick={handleRegister} style={{fontSize: "12px", color:"#3498db", cursor:"pointer", letterSpacing: "2px"}}>Register</b></p>
                        </div>
                        <div className={`forgot glass-effect ${showForgotPassword ? "move-in-forgot" : "move-out-forgot"}`}>
                            <div className='d-flex justify-content-end'>
                                <button  onClick={handleBackForgot} className='Btns'>
                                    <svg height="18" width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1024 1024"><path d="M874.690416 495.52477c0 11.2973-9.168824 20.466124-20.466124 20.466124l-604.773963 0 188.083679 188.083679c7.992021 7.992021 7.992021 20.947078 0 28.939099-4.001127 3.990894-9.240455 5.996574-14.46955 5.996574-5.239328 0-10.478655-1.995447-14.479783-5.996574l-223.00912-223.00912c-3.837398-3.837398-5.996574-9.046027-5.996574-14.46955 0-5.433756 2.159176-10.632151 5.996574-14.46955l223.019353-223.029586c7.992021-7.992021 20.957311-7.992021 28.949332 0 7.992021 8.002254 7.992021 20.957311 0 28.949332l-188.073446 188.073446 604.753497 0C865.521592 475.058646 874.690416 484.217237 874.690416 495.52477z"></path></svg>
                                    <span style={{fontSize: "12px"}}>Back</span>
                                </button>
                            </div>
                            <div>
                                <Forgot/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Routes>
                <Route path='/home' element={<Home />} />
                <Route path='/register' element={<Register />} />
                <Route path='/reset-people-password' element={<ResetPeoplePassword/>} />
                {location.pathname !== '/people' && (
                    <Route path='*' element={<NotFound />} />
                )}
            </Routes>
        </div>
    );
};

const Forgot = () => {

    const [username, setUsername] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); 
    const [showConformPassword, setShowConformPassword] = useState(false); 
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConformPasswordVisibility = () => {
        setShowConformPassword(!showConformPassword);
    };
    const handleReset = (e) => {
        e.preventDefault();
        axios.post(`${API_PATH}/pray_for_priest/reset_user.php`, {
            username: username,
            oldPassword: oldPassword
        })
        .then((response) => {
            if (response.data.status === "success" || response.data.status === "partial_success") {
                setSuccess(true);
                setOldPassword("");
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.error("There was an error with the request", error);
        });
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        // Password validation
        if (newPassword.length < 6) {
            setError("New password must be at least 6 characters long.");
            return;
        }
        if (!/[A-Z]/.test(newPassword)) {
            setError("New password must contain at least one uppercase letter.");
            return;
        }
        if (newPassword !== confirmPassword) {
            setError("New password and confirm password do not match.");
            return;
        }

        // Send request to reset the password
        axios.post(`${API_PATH}/pray_for_priest/update_people.php`, {
            username: username,
            newPassword: newPassword
        })
        .then((response) => {
            if (response.data.status === "success") {
                alert("Password updated successfully!");
                setConfirmPassword("");
                setNewPassword("");
                setSuccess("");
                setError("");
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.error("There was an error with the request", error);
        });
    };

    return (
        <div>
            {!success ? (
                <form onSubmit={handleReset}>
                    <label className='form-label'>User Name</label>
                    <input 
                        type="text" 
                        className="form-control"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <label className='form-label mt-2'>Old Password</label>
                    <div className='input-group'>
                        <input 
                            type={showPassword ? "text" : "password"} 
                            className="form-control"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <span className="input-group-text" style={{backgroundColor: "white", border: "none"}}>
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={togglePasswordVisibility} style={{ cursor: "pointer", fontSize: "12px" }}/>
                        </span>
                    </div>
                    {error && <p className="error-message mt-1" style={{ fontSize: "12px", color: "red" }}>{error}</p>}
                    <button className="button-people mt-3" type="submit">
                        Reset Password
                        <svg className="icon" viewBox="0 0 24 24" fill="currentColor">
                            <path
                            fillRule="evenodd"
                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                            clipRule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </form>
            ) : (
                <div className="success-message">
                    <form onSubmit={handleUpdate}>
                        <label className='form-label mt-2'>New Password</label>
                        <div className='input-group'>
                            <input 
                                type={showPassword ? "text" : "password"} 
                                className="form-control"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <span className="input-group-text" style={{backgroundColor: "white", border: "none"}}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={togglePasswordVisibility} style={{ cursor: "pointer", fontSize: "12px" }}/>
                            </span>
                        </div>
                        <label className='form-label mt-2'>Conform New Password</label>
                        <div className='input-group'>
                            <input 
                                type={showConformPassword ? "text" : "password"} 
                                className="form-control"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <span className="input-group-text" style={{backgroundColor: "white", border: "none"}}>
                                <FontAwesomeIcon icon={showConformPassword ? faEyeSlash : faEye} onClick={toggleConformPasswordVisibility} style={{ cursor: "pointer", fontSize: "12px" }}/>
                            </span>
                        </div>
                        {error && <p className="error-message mt-1" style={{ fontSize: "12px", color: "red" }}>{error}</p>}
                        <button className="button-people mt-3" type="submit">
                            Submit
                            <svg className="icon" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                fillRule="evenodd"
                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </form>
                </div>
            )}
        </div>
    )
}

const NotFound = () => {
    return (
      <div className="text-center mt-5">
        <h1>404 - Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    );
  };
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import axios from 'axios';
import { API_PATH } from '../API_PATH';
import "./CSS/PeopleProfile.css";

export const PeopleProfile = ({renderSectionHandler }) => {

    const [peopleData, setPeopleData] = useState([]);
    const [image, setImage] = useState("");
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [dioceses, setDioceses] = useState([]);
    const [cities, setCities] = useState([]);
    const [ church, setChurch ] = useState([]);
    const [prayerCount, setPrayerCount] = useState([]);
    const [followData, setFollowData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDiocese, setSelectedDiocese] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedChurch, setSelectedChurch ] =useState(null);

    const formatNumber = (num) => {
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1) + 'M';
      } else if (num >= 1000) {
          return (num / 1000).toFixed(1) + 'K';
      } else {
          return num;
      }
  };

    useEffect(() => {
        const storedEmail = sessionStorage.getItem("userEmail");
        const storedSub = sessionStorage.getItem("userSub");
  
        // Fetch all data from `priests_detais` table
        axios.get(`${API_PATH}/pray_for_priest/get_people_details.php`)
            .then(response => {
  
                // Check session storage against the fetched data
                const matchedPeople = response.data.find(people => {
                    return (storedEmail && people.email === storedEmail) ||
                           (storedSub && people.sub === storedSub);
                });
  
                if (matchedPeople) {
                    setPeopleData(matchedPeople);
                    setImage(matchedPeople.profile_image);
                    setSelectedCountry({ value: matchedPeople.country_id, label: matchedPeople.country });
                    setSelectedState({ value: matchedPeople.state_id, label: matchedPeople.state });
                    setSelectedDiocese({ value: matchedPeople.diocese_id, label: matchedPeople.diocese });
                    setSelectedCity({ value: matchedPeople.city_id, label: matchedPeople.city });
                    setSelectedChurch({ value: matchedPeople.church_id, label: matchedPeople.church });
                  }
            })
            .catch(error => console.error("Error fetching people details:", error));
    }, []);

    useEffect(() => {
      // Fetch prayer count data
      axios.get(`${API_PATH}/pray_for_priest/prayering_support.php`)
          .then(response => {
            const filteredRequests = response.data
            .filter(request => request.people_id === peopleData.people_id)
              setPrayerCount(filteredRequests);
          })
          .catch(error => console.error("Error fetching prayer count details:", error));
  }, [peopleData.people_id]);

  useEffect(() => {
    axios.get(`${API_PATH}/pray_for_priest/get_follow_priests.php`)
        .then(response => {
          const filteredRequests = response.data
          .filter(request => request.people_id === peopleData.people_id)
            setFollowData(filteredRequests);
        })
        .catch(error => console.error("Error fetching follow data:", error));
    }, [peopleData.people_id]);

    useEffect(() => {
      axios.get(`${API_PATH}/pray_for_priest/location_people.php?countries=true`)
          .then(response => {
              setCountries(response.data.map(country => ({
                  value: country.id,
                  label: country.name
              })));
          })
          .catch(error => console.error("Error fetching countries:", error));
    }, []);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        setSelectedState(null);
        setSelectedDiocese(null);
        setSelectedCity(null)
        setSelectedChurch(null);
        // Fetch states based on selected country
        axios.get(`${API_PATH}/pray_for_priest/location_people.php?states=true&country_id=${selectedOption.value}`)
            .then(response => {
                setStates(response.data.map(state => ({
                    value: state.id,
                    label: state.name
                })));
            })
            .catch(error => console.error("Error fetching states:", error));
    };
  
    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedDiocese(null);
        setSelectedCity(null)
        setSelectedChurch(null);
        // Fetch dioceses based on selected state
        axios.get(`${API_PATH}/pray_for_priest/location_people.php?dioceses=true&state_id=${selectedOption.value}`)
            .then(response => {
                setDioceses(response.data.map(diocese => ({
                    value: diocese.id,
                    label: diocese.name
                })));
            })
            .catch(error => console.error("Error fetching dioceses:", error));
    };
  
    const handleDioceseChange = (selectedOption) => {
        setSelectedDiocese(selectedOption);
        setSelectedCity(null)
        setSelectedChurch(null);
        // Fetch cities based on selected diocese
        axios.get(`${API_PATH}/pray_for_priest/location_people.php?cities=true&diocese_id=${selectedOption.value}`)
            .then(response => {
                setCities(response.data.map(city => ({
                    value: city.id,
                    label: city.name
                })));
            })
            .catch(error => console.error("Error fetching cities:", error));
    };
  
    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption)
        setSelectedChurch(null);
        axios.get(`${API_PATH}/pray_for_priest/location_people.php?church=true&city_id=${selectedOption.value}`)
            .then(response => {
                setChurch(response.data.map(church => ({
                    value: church.id,
                    label: church.name
                })));
            })
            .catch(error => console.error("Error fetching cities:", error));
    }
  
    const handleChurchChange = (selectedOption) => {
      setSelectedChurch(selectedOption)
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type.split('/')[1]; // Get the file type (jpg, png, etc.)
            if (fileType === 'jpeg' || fileType === 'png') {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImage(reader.result); // Set the selected image as the profile picture
                };
                reader.readAsDataURL(file); // Read the file as a data URL
            } else {
                alert("Please select a valid image file (JPEG or PNG).");
            }
        }
    };

    const handleSaveChanges = () => {

        if (!peopleData.people_name) {
            alert("Please enter the first name.");
            return;
          }

          if (!peopleData.last_name) {
            alert("Please enter the last name")
          }
        
          if (!peopleData.email || !/\S+@\S+\.\S+/.test(peopleData.email)) {
            alert("Please enter a valid email address.");
            return;
          }
        
          if (!peopleData.contact_number) {
            alert("Please enter the contact number.");
            return;
          }
        
          if (!selectedCountry || !selectedCountry.label) {
            alert("Please select a country.");
            return;
          }
        
          if (!selectedState || !selectedState.label) {
            alert("Please select a state.");
            return;
          }
        
          if (!selectedDiocese || !selectedDiocese.label) {
            alert("Please select a diocese.");
            return;
          }
        
          if (!selectedCity || !selectedCity.label) {
            alert("Please select a city.");
            return;
          }
        
          if (!selectedChurch || !selectedChurch.label) {
            alert("Please select a church.");
            return;
          }
        
          if (!image) {
            alert("Please upload a profile image.");
            return;
          }
          
        // Prepare data to send to the backend
        const updatedData = {
          people_id: peopleData.people_id,
          people_name: peopleData.people_name,
          last_name: peopleData.last_name,
          email: peopleData.email,
          contact_number: peopleData.contact_number,
          country: selectedCountry?.label || "",
          state: selectedState?.label || "",
          diocese: selectedDiocese?.label || "",
          city: selectedCity?.label || "",
          church: selectedChurch?.label || "",
          profile_image: image,
        };
      
        axios
          .post(`${API_PATH}/pray_for_priest/update_people_profile.php`, updatedData)
          .then((response) => {
            console.log("Profile updated successfully:", response.data);
            alert("Profile updated successfully!");
          })
          .catch((error) => {
            console.error("Error updating profile:", error);
            alert("Failed to update profile. Please try again.");
          });
      };

    const customStyles = {
        control: (provided) => ({
          ...provided,
          border: 'none', 
          borderBottom: '2px solid #1F5258',
          borderRadius: 0,
          paddingBottom: '5px',
          cursor: "pointer",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#1F5258', 
          fontWeight: 'bold',
          opacity: '0.7'
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: '#1F5258', 
          padding: 4,
        }),
      };

      const handleCancle = () => {
        renderSectionHandler('home');
      }

    return (
        <div className="entire-profile">
            <div className="profile-cointainer">
                <div className="profile-image-container">
                    <div className="profile-img-content">
                        <div className="profile-image">
                            <img className="img-fluid" src={image.startsWith("data:") ? image : `${API_PATH}/pray_for_priest/${image}`} alt="Profile DP of User" />
                        </div>
                        <i className="faImage-icon" onClick={() => document.getElementById('fileInput').click()}><FontAwesomeIcon icon={faImage} /></i>
                        <input type="file" style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={handleFileChange} id="fileInput" />
                    </div>
                    <div className="profile-following-cont">
                        <div>
                            <b className="text-of-count">Following Priest</b><br />
                            <b className="count-num">{formatNumber(followData.length)}</b>
                        </div>
                        <div>
                            <b className="text-of-count">Total Prayers</b><br />
                            <b className="count-num">{formatNumber(prayerCount.length)}</b>
                        </div>
                    </div>
                </div>
                <div className="fetch-profile-content">
                    <div>
                        <input className="input-design" type="text" value={peopleData.people_name} onChange={(e) => setPeopleData({...peopleData, people_name: e.target.value})} placeholder="First name" /><br />
                        <input className="input-design" type="text" value={peopleData.last_name} onChange={(e) => setPeopleData({...peopleData, last_name: e.target.value})} placeholder="Last name" /><br />
                        <input className="input-design" type="email" value={peopleData.email} placeholder='Email ID' /><br />
                        <input className="input-design" type="number" value={peopleData.contact_number} onChange={(e) => setPeopleData({...peopleData, contact_number: e.target.value})} placeholder='Contact Number' />
                    </div>
                    <div>
                        <Select placeholder="Select Country" options={countries} value={selectedCountry} onChange={handleCountryChange} isSearchable styles={customStyles}/>
                        <Select placeholder="Select State" options={states} value={selectedState} onChange={handleStateChange} isSearchable styles={customStyles}/>
                        <Select placeholder="Select Dioceses" options={dioceses} value={selectedDiocese} onChange={handleDioceseChange} isSearchable styles={customStyles}/>
                        <Select placeholder="Select Cities" options={cities} value={selectedCity} onChange={handleCityChange} isSearchable styles={customStyles}/>
                        <Select placeholder="Select Church Name" options={church} value={selectedChurch} onChange={handleChurchChange} isSearchable styles={customStyles}/>
                    </div>
                </div>
                <div>
                    <button className="profile-change" onClick={handleSaveChanges}>Save the Changes</button>
                    <button className="profile-change" onClick={handleCancle} >Cancle</button>
                </div>
            </div>
        </div>
    )
}
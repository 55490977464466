import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { API_PATH } from "../API_PATH";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import "./CSS/Church.css";

export const Church = () => {
    const [inputs, setInputs] = useState({
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        option5: ''
    });

    const [showInput, setShowInput] = useState({
        input1: false,
        input2: false,
        input3: false,
        input4: false,
        input5: false
    });

    const [options, setOptions] = useState([]); 
    const [stateOptions, setStateOptions] = useState([]); 
    const [dioceseOptions, setDioceseOptions] = useState([]); 
    const [cityOptions, setCityOptions] = useState([]);
    const [churchOptions, setChurchOptions] = useState([]);
    const [selectedCountryId, setSelectedCountryId] = useState(null); 
    const [selectedStateId, setSelectedStateId] = useState(null); 
    const [selectedDioceseId, setSelectedDioceseId] = useState(null);
    const [selectedCityId, setSelectedCityId] = useState(null);



    // Fetch countries on component mount
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${API_PATH}/pray_for_priest/country.php`);
                const countries = response.data.map(country => ({
                    value: country.id,
                    label: country.name,
                }));
                setOptions(countries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);

    // Fetch states based on selected country
    useEffect(() => {
        if (selectedCountryId) {
            const fetchStates = async () => {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/state.php?country_id=${selectedCountryId}`);
                    const states = response.data.map(state => ({
                        value: state.id,
                        label: state.name,
                    }));
                    setStateOptions(states);
                } catch (error) {
                    console.error('Error fetching states:', error);
                }
            };
            fetchStates();
        }
    }, [selectedCountryId]);

    // Fetch dioceses based on selected state
    useEffect(() => {
        if (selectedStateId) {
            const fetchDioceses = async () => {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/diocese.php?state_id=${selectedStateId}`);
                    const dioceses = response.data.map(diocese => ({
                        value: diocese.id,
                        label: diocese.name,
                    }));
                    setDioceseOptions(dioceses);
                } catch (error) {
                    console.error('Error fetching dioceses:', error);
                }
            };
            fetchDioceses();
        }
    }, [selectedStateId]);

    useEffect(() => {
        if (selectedDioceseId) {
            const fetchCities = async () => {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/city.php?diocese_id=${selectedDioceseId}`);
                    const cities = response.data.map(city => ({
                        value: city.id,
                        label: city.name,
                    }));
                    setCityOptions(cities);
                } catch (error) {
                    console.error('Error fetching cities:', error);
                }
            };
            fetchCities();
        }
    }, [selectedDioceseId]);

    // Fetch churches based on selected city
    useEffect(() => {
        if (selectedCityId) {
            const fetchChurches = async () => {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/church.php?city_id=${selectedCityId}`);
                    const churches = response.data.map(church => ({
                        value: church.id,
                        label: church.name,
                    }));
                    setChurchOptions(churches);
                } catch (error) {
                    console.error('Error fetching churches:', error);
                }
            };
            fetchChurches();
        }
    }, [selectedCityId]);


    const handleSelectChange = (selectedOption, inputType) => {

        if (selectedOption.value === 'Other') {
            setShowInput({ ...showInput, [inputType]: true });
            return;
        }

        setInputs({ ...inputs, [inputType]: selectedOption.label });
    
        // Handle other inputType changes
        if (inputType === 'input1') {  // Country
            setInputs({
                ...inputs,
                option1: selectedOption.label,
                option2: null,
                option3: null,
                option4: null,
                option5: null,
            });
    
            setSelectedCountryId(selectedOption.value);
            setSelectedStateId(null);
            setStateOptions([]);
            setDioceseOptions([]);
            setCityOptions([]);
            setChurchOptions([]);
        } else if (inputType === 'input2') {  // State
            setInputs({
                ...inputs,
                option2: selectedOption.label,
                option3: null,
                option4: null,
                option5: null,
            });
    
            setSelectedStateId(selectedOption.value);
            setDioceseOptions([]);
            setCityOptions([]);
            setChurchOptions([]);
        } else if (inputType === 'input3') {  // Diocese
            setInputs({
                ...inputs,
                option3: selectedOption.label,
                option4: null,
                option5: null,
            });
    
            setSelectedDioceseId(selectedOption.value);
            setCityOptions([]);
            setChurchOptions([]);
        } else if (inputType === 'input4') {  // City
            setInputs({
                ...inputs,
                option4: selectedOption.label,
                option5: null,
            });
    
            setSelectedCityId(selectedOption.value);
            setChurchOptions([]);
        } else if (inputType === 'input5') {  // Church
            setInputs({
                ...inputs,
                option5: selectedOption.label,
            });
        }
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
    };

    const handleSave = async (optionKey) => {
        const value = inputs[optionKey];

        if (!value.trim()) {
            alert(`${optionKey.replace('option', 'Option ')} name cannot be empty.`);
            return;
        }

        let apiUrl;

        if (optionKey === 'option1') {
            apiUrl = `${API_PATH}/pray_for_priest/country.php`;
        } else if (optionKey === 'option2') {
            apiUrl = `${API_PATH}/pray_for_priest/state.php`;
            if (!selectedCountryId) {
                alert("Please select a country before saving the state.");
                return;
            }
        } else if (optionKey === 'option3') {
            apiUrl = `${API_PATH}/pray_for_priest/diocese.php`;
            if (!selectedStateId) {
                alert("Please select a state before saving the diocese.");
                return;
            }
        } else if (optionKey === 'option4') {
            apiUrl = `${API_PATH}/pray_for_priest/city.php`; 
            if (!selectedDioceseId) {
                alert("Please select a diocese before saving the city.");
                return;
            }
        } else {
            apiUrl = `${API_PATH}/pray_for_priest/church.php`;
            if (!selectedCityId) {
                alert("Please select a city before saving the church.");
                return;
            }
        }

        try {
            await axios.post(apiUrl, {
                name: value,
                country_id: optionKey === 'option2' ? selectedCountryId : undefined, // Pass country_id for state only
                state_id: optionKey === 'option3' ? selectedStateId : undefined, // Pass state_id for diocese only
                diocese_id: optionKey === 'option4' ? selectedDioceseId : undefined,
                city_id: optionKey === 'option5' ? selectedCityId : undefined,
            });

            // Fetch updated countries, states, or dioceses after saving
            if (optionKey === 'option1') {
                const response = await axios.get(`${API_PATH}/pray_for_priest/country.php`);
                const countries = response.data.map(country => ({
                    value: country.id,
                    label: country.name,
                }));
                setOptions(countries);
            } else if (optionKey === 'option2') {
                const response = await axios.get(`${API_PATH}/pray_for_priest/state.php?country_id=${selectedCountryId}`);
                const states = response.data.map(state => ({
                    value: state.id,
                    label: state.name,
                }));
                setStateOptions(states);
            } else if (optionKey === 'option3') {
                const response = await axios.get(`${API_PATH}/pray_for_priest/diocese.php?state_id=${selectedStateId}`);
                const dioceses = response.data.map(diocese => ({
                    value: diocese.id,
                    label: diocese.name,
                }));
                setDioceseOptions(dioceses);
            } else if (optionKey === 'option4') {
                const response = await axios.get(`${API_PATH}/pray_for_priest/city.php?diocese_id=${selectedDioceseId}`);
                const cities = response.data.map(city => ({
                    value: city.id,
                    label: city.name,
                }));
                setCityOptions(cities);
            } else {
                const response = await axios.get(`${API_PATH}/pray_for_priest/church.php?city_id=${selectedCityId}`);
                const churches = response.data.map(church => ({
                    value: church.id,
                    label: church.name,
                }));
                setChurchOptions(churches);
            }

            setShowInput({ ...showInput, [optionKey]: false });
            setInputs({ ...inputs, [optionKey]: '' });
        } catch (error) {
            console.error('Error saving:', error);
        }
    };

    const customStyles = {
        control: (provided) => ({
          ...provided,
          border: 'none', 
          borderBottom: '2px solid #1F5258',
          borderRadius: 0,
          paddingBottom: '5px',
          cursor: "pointer",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#1F5258', 
          fontWeight: 'bold',
          opacity: '0.7'
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: '#1F5258', 
          padding: 4,
        }),
      };

    return (
        <div>
            <div className="row justify-content-center">
                {/* Country Selection */}
                <div className='col-lg-2 col-md-4 col-sm-4 col-6 mt-2'>
                    {!showInput.input1 ? (
                        <div>
                            <Select
                                placeholder="Country"
                                name="option1"
                                value={inputs.option1 ? options.find(option => option.label === inputs.option1) : null}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'input1')}
                                options={[...options, { value: 'Other', label: 'Other' }]}
                                isSearchable
                                styles={customStyles}
                            />
                        </div>
                    ) : (
                        <div>
                            <input
                                type="text"
                                name="option1"
                                value={inputs.option1}
                                onChange={handleInputChange}
                                placeholder="Enter country name"
                                className='form-control'
                            />
                            <button className='butto' onClick={() => handleSave('option1')}>Save</button>
                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input1: false })}>Cancel</button>
                        </div>
                    )}
                </div>
                {/* State Selection */}
                <div className='col-lg-2 col-md-4 col-sm-4 col-6 mt-2'>
                    {!showInput.input2 ? (
                        <div>
                            <Select
                                placeholder="State"
                                name="option2"
                                value={inputs.option2 ? stateOptions.find(option => option.label === inputs.option2) : null}
                                onChange={(selectedOption) => {
                                    handleSelectChange(selectedOption, 'input2');
                                    setSelectedStateId(selectedOption.value); // Set the selected state ID
                                }}
                                options={[...stateOptions, { value: 'Other', label: 'Other' }]}
                                isSearchable
                                styles={customStyles}
                            />
                        </div>
                    ) : (
                        <div>
                            <input
                                type="text"
                                name="option2"
                                value={inputs.option2}
                                onChange={handleInputChange}
                                placeholder="Enter state name"
                                className='form-control'
                            />
                            <button className='butto' onClick={() => handleSave('option2')}>Save</button>
                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input2: false })}>Cancel</button>
                        </div>
                    )}
                </div>
                {/* Diocese Selection */}
                <div className='col-lg-2 col-md-4 col-sm-4 col-6 mt-2'>
                    {!showInput.input3 ? (
                        <div>
                            <Select
                                placeholder="Diocese"
                                name="option3"
                                value={inputs.option3 ? dioceseOptions.find(option => option.label === inputs.option3) : null}
                                onChange={(selectedOption) => {
                                    handleSelectChange(selectedOption, 'input3');
                                    setSelectedDioceseId(selectedOption.value); // Set the selected diocese ID
                                }}
                                options={[...dioceseOptions, { value: 'Other', label: 'Other' }]}
                                isSearchable
                                styles={customStyles}
                            />
                        </div>
                    ) : (
                        <div>
                            <input
                                type="text"
                                name="option3"
                                value={inputs.option3}
                                onChange={handleInputChange}
                                placeholder="Enter diocese name"
                                className='form-control'
                            />
                            <button className='butto' onClick={() => handleSave('option3')}>Save</button>
                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input3: false })}>Cancel</button>
                        </div>
                    )}
                </div>
                {/* Cities Selection */}
                <div className='col-lg-2 col-md-4 col-sm-4 col-6 mt-2'>
                    {!showInput.input4 ? (
                        <div>
                            <Select
                                placeholder="City"
                                name="option4"
                                value={inputs.option4 ? cityOptions.find(option => option.label === inputs.option4) : null}
                                onChange={(selectedOption) => {
                                    handleSelectChange(selectedOption, 'input4');
                                    setSelectedCityId(selectedOption.value); // Set the selected city ID
                                }}
                                options={[...cityOptions, { value: 'Other', label: 'Other' }]}
                                isSearchable
                                styles={customStyles}
                            />
                        </div>
                    ) : (
                        <div>
                            <input
                                type="text"
                                name="option4"
                                value={inputs.option4}
                                onChange={handleInputChange}
                                placeholder="Enter city name"
                                className='form-control'
                            />
                            <button className='butto' onClick={() => handleSave('option4')}>Save</button>
                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input4: false })}>Cancel</button>
                        </div>
                    )}
                </div>
                {/* Church Selection */}
                <div className='col-lg-2 col-md-4 col-sm-4 col-6 mt-2'>
                    {!showInput.input5 ? (
                        <div>
                            <Select
                                placeholder="Church"
                                name="option5"
                                value={inputs.option5 ? churchOptions.find(option => option.label === inputs.option5) : null}
                                onChange={(selectedOption) => {
                                    handleSelectChange(selectedOption, 'input5');
                                }}
                                options={[...churchOptions, { value: 'Other', label: 'Other' }]}
                                isSearchable
                                styles={customStyles}
                            />
                        </div>
                    ) : (
                        <div>
                            <input
                                type="text"
                                name="option5"
                                value={inputs.option5}
                                onChange={handleInputChange}
                                placeholder="Enter church name"
                                className='form-control'
                            />
                            <button className='butto' onClick={() => handleSave('option5')}>Save</button>
                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input5: false })}>Cancel</button>
                        </div>
                    )}
                </div>
            </div>
            <div className='mt-3'>
                <Placelist/>
            </div>
        </div>
    );
};


const Placelist = () => {
    const [locations, setLocations] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(9);   

    const totalPages = Math.ceil(locations.length / rowsPerPage);
    const maxPageButtons = 5;

  // Fetch data from the PHP API
  useEffect(() => {
    axios
      .get(`${API_PATH}/pray_for_priest/church_location.php`) // Replace with your PHP script URL
      .then((response) => {
        setLocations(response.data); // Set the data into the state
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${API_PATH}/pray_for_priest/church_location.php`, {
        data: { church_id: id },
      })
      .then((response) => {
        console.log(response.data);
        setLocations(locations.filter((location) => location.church_id !== id)); // Remove from the list
      })
      .catch((error) => {
        console.error("There was an error deleting the data!", error);
      });
  };

  // Handle pagination
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Get current rows to display
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = locations.slice(indexOfFirstRow, indexOfLastRow);

  // Determine page numbers to display in pagination
  const startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  const endPage = Math.min(totalPages, startPage + maxPageButtons - 1);
  const paginationRange = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  const renderCellContent = (content) => {
    return content.length > 20 ? `${content.slice(0, 20)}...` : content;
};

  return (
    <div>
        <div className='table-container'>
            <table className="w-100">
                <thead className='text-center'>
                    <tr className='table-tr'>
                    <th className='table-th'>Country</th>
                    <th className='table-th'>State</th>
                    <th className='table-th'>Diocese</th>
                    <th className='table-th'>City</th>
                    <th className='table-th'>Church</th>
                    <th className='table-action'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentRows.length > 0 ? (
                      currentRows.map((location) => (
                        <tr key={location.church_id}>
                        <td className='table-td'><span className="tooltip-span">{location.country}</span>{renderCellContent(location.country)}</td>
                        <td className='table-td'><span className="tooltip-span">{location.state}</span>{renderCellContent(location.state)}</td>
                        <td className='table-td'><span className="tooltip-span">{location.diocese}</span>{renderCellContent(location.diocese)}</td>
                        <td className='table-td'><span className="tooltip-span">{location.city}</span>{renderCellContent(location.city)}</td>
                        <td className='table-td'><span className="tooltip-span">{location.church}</span>{renderCellContent(location.church)}</td>
                        <td className='text-center table-delete'>
                            <button
                                onClick={() => handleDelete(location.church_id)}
                                className="danger-btn"
                            >
                            Delete
                            </button>
                        </td>
                        </tr>
                    ))
                    ) : (
                    <tr>
                        <td colSpan="6">No data available</td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
        <div className="pagination">
            <button
                onClick={handlePrevious}
                disabled={currentPage === 1}
                className="success-btn"
            >
                <FontAwesomeIcon icon={faAnglesLeft} />
                Previous
            </button>
            {paginationRange.map((page) => (
            <button
                key={page}
                onClick={() => handlePageClick(page)}
                className={`success-btn ${currentPage === page ? "active" : ""}`}
            >
                {page}
            </button>
            ))}

            <button
                onClick={handleNext}
                disabled={currentPage === totalPages}
                className="success-btn"
            >
                Next
                <FontAwesomeIcon icon={faAnglesRight} />
            </button>
        </div>
    </div>
  );
};
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../API_PATH';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import "./CSS/PriestProfile.css";

export const PriestProfile = ({renderSectionHandler }) => {

    const [priestData, setPriestData] = useState([]);
    const [image, setImage] = useState("");
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [dioceses, setDioceses] = useState([]);
    const [cities, setCities] = useState([]);
    const [prayerRequests, setPrayerRequests] = useState([]);
    const [followData, setFollowData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDiocese, setSelectedDiocese] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        } else {
            return num;
        }
    };

    useEffect(() => {
        const storedEmail = sessionStorage.getItem("userEmail");
        const storedSub = sessionStorage.getItem("userSub");

        // Fetch all data from `priests_detais` table
        axios.get(`${API_PATH}/pray_for_priest/get_priest_details.php`)
            .then(response => {

                // Check session storage against the fetched data
                const matchedPriest = response.data.find(priest => {
                    return (storedEmail && priest.email === storedEmail) ||
                           (storedSub && priest.sub === storedSub);
                });

                if (matchedPriest) {
                    setPriestData(matchedPriest);
                    setImage(matchedPriest.profile_image);
                    setSelectedCountry({ value: matchedPriest.country_id, label: matchedPriest.country });
                    setSelectedState({ value: matchedPriest.state_id, label: matchedPriest.state });
                    setSelectedDiocese({ value: matchedPriest.diocese_id, label: matchedPriest.diocese });
                    setSelectedCity({ value: matchedPriest.city_id, label: matchedPriest.city });
                }
            })
            .catch(error => console.error("Error fetching priest details:", error));
    }, []);

    useEffect(() => {
        axios.get(`${API_PATH}/pray_for_priest/location_people.php?countries=true`)
            .then(response => {
                setCountries(response.data.map(country => ({
                    value: country.id,
                    label: country.name
                })));
            })
            .catch(error => console.error("Error fetching countries:", error));
      }, []);

      useEffect(() => {
        // Fetch all prayer requests
        axios.get(`${API_PATH}/pray_for_priest/prayer_request.php`)
            .then(response => {
                const filteredRequests = response.data
                    .filter(request => request.priest_id === priestData.priest_id)
                    .sort((a, b) => new Date(b.posted_on) - new Date(a.posted_on)); // Sort by date and time in descending order

                setPrayerRequests(filteredRequests);
            })
            .catch(error => console.error("Error fetching prayer requests:", error));
    }, [priestData.priest_id]);


    useEffect(() => {
        // Fetch follow data
        axios.get(`${API_PATH}/pray_for_priest/get_follow_priests.php`)
            .then(response => {
                const filteredRequests = response.data
                    .filter(request => request.priest_id === priestData.priest_id)
                setFollowData(filteredRequests);
            })
            .catch(error => console.error("Error fetching follow data:", error));
    }, [priestData.priest_id]);
  
      const handleCountryChange = (selectedOption) => {
          setSelectedCountry(selectedOption);
          setSelectedState(null);
          setSelectedDiocese(null);
          setSelectedCity(null)
          // Fetch states based on selected country
          axios.get(`${API_PATH}/pray_for_priest/location_people.php?states=true&country_id=${selectedOption.value}`)
              .then(response => {
                  setStates(response.data.map(state => ({
                      value: state.id,
                      label: state.name
                  })));
              })
              .catch(error => console.error("Error fetching states:", error));
      };
    
      const handleStateChange = (selectedOption) => {
          setSelectedState(selectedOption);
          setSelectedDiocese(null);
          setSelectedCity(null)
          // Fetch dioceses based on selected state
          axios.get(`${API_PATH}/pray_for_priest/location_people.php?dioceses=true&state_id=${selectedOption.value}`)
              .then(response => {
                  setDioceses(response.data.map(diocese => ({
                      value: diocese.id,
                      label: diocese.name
                  })));
              })
              .catch(error => console.error("Error fetching dioceses:", error));
      };
    
      const handleDioceseChange = (selectedOption) => {
          setSelectedDiocese(selectedOption);
          setSelectedCity(null)
          // Fetch cities based on selected diocese
          axios.get(`${API_PATH}/pray_for_priest/location_people.php?cities=true&diocese_id=${selectedOption.value}`)
              .then(response => {
                  setCities(response.data.map(city => ({
                      value: city.id,
                      label: city.name
                  })));
              })
              .catch(error => console.error("Error fetching cities:", error));
      };
    
      const handleCityChange = (selectedOption) => {
          setSelectedCity(selectedOption)
      }

      const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type.split('/')[1]; // Get the file type (jpg, png, etc.)
            if (fileType === 'jpeg' || fileType === 'png') {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImage(reader.result); // Set the selected image as the profile picture
                };
                reader.readAsDataURL(file); // Read the file as a data URL
            } else {
                alert("Please select a valid image file (JPEG or PNG).");
            }
        }
    };

    const handleSaveChanges = () => {

        if (!priestData.priest_name) {
            alert("Please enter the first name.");
            return;
          }
        
          if (!priestData.email || !/\S+@\S+\.\S+/.test(priestData.email)) {
            alert("Please enter a valid email address.");
            return;
          }
        
          if (!priestData.contact_number) {
            alert("Please enter the contact number.");
            return;
          }

          if (!priestData.last_name) {
            alert("Please enter the last name.");
            return;
          }
        
          if (!selectedCountry || !selectedCountry.label) {
            alert("Please select a country.");
            return;
          }
        
          if (!selectedState || !selectedState.label) {
            alert("Please select a state.");
            return;
          }
        
          if (!selectedDiocese || !selectedDiocese.label) {
            alert("Please select a diocese.");
            return;
          }
        
          if (!selectedCity || !selectedCity.label) {
            alert("Please select a city.");
            return;
          }
        
        
          if (!image) {
            alert("Please upload a profile image.");
            return;
          }
          
        // Prepare data to send to the backend
        const updatedData = {
          priest_id: priestData.priest_id,
          priest_name: priestData.priest_name,
          last_name: priestData.last_name,
          email: priestData.email,
          contact_number: priestData.contact_number,
          country: selectedCountry?.label || "",
          state: selectedState?.label || "",
          diocese: selectedDiocese?.label || "",
          city: selectedCity?.label || "",
          profile_image: image,
        };
      
        axios
          .post(`${API_PATH}/pray_for_priest/update_priest_profile.php`, updatedData)
          .then((response) => {
            console.log("Profile updated successfully:", response.data);
            alert("Profile updated successfully!");
          })
          .catch((error) => {
            console.error("Error updating profile:", error);
            alert("Failed to update profile. Please try again.");
          });
      };

    const customStyles = {
        control: (provided) => ({
          ...provided,
          border: 'none', 
          borderBottom: '2px solid #1F5258',
          borderRadius: 0,
          paddingBottom: '5px',
          cursor: "pointer",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#1F5258', 
          fontWeight: 'bold',
          opacity: '0.7'
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: '#1F5258', 
          padding: 4,
        }),
      };

      const handleCancle = () => {
        renderSectionHandler('home');
      }
    
    return (
        <div className="entire-profile">
            <div className="profile-cointainer">
                <div className="profile-image-container">
                    <div className="profile-img-content">
                        <div className="profile-image">
                            <img className="img-fluid" src={image.startsWith("data:") ? image : `${API_PATH}/pray_for_priest/${image}`} alt="Profile DP of User" />
                        </div>
                        <i className="faImage-icon" onClick={() => document.getElementById('fileInput').click()}><FontAwesomeIcon icon={faImage} /></i>
                        <input type="file" style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={handleFileChange}    id="fileInput" />
                    </div>
                    <div className="profile-following-cont">
                        <div>
                            <b className="text-of-count">Followers</b><br />
                            <b className="count-num">{formatNumber(followData.length)}</b>
                        </div>
                        <div>
                            <b className="text-of-count">Total Prayers Request</b><br />
                            <b className="count-num">{formatNumber(prayerRequests.length)}</b>
                        </div>
                    </div>
                </div>
                <div className="fetch-profile-content">
                    <div>
                        <input className="input-design" type="text" value={priestData.priest_name} onChange={(e) => setPriestData({...priestData, priest_name: e.target.value})} placeholder="First name" /><br />
                        <input className="input-design" type="text" value={priestData.last_name} onChange={(e) => setPriestData({...priestData, last_name: e.target.value})} placeholder="Last name" /><br />
                        <input className="input-design" type="email" value={priestData.email} placeholder='Email ID' /><br />
                        <input className="input-design" type="number" value={priestData.contact_number} onChange={(e) => setPriestData({...priestData, contact_number: e.target.value})} placeholder='Contact Number' />
                    </div>
                    <div>
                        <Select placeholder="Select Country" options={countries} value={selectedCountry} onChange={handleCountryChange} isSearchable styles={customStyles}/>
                        <Select placeholder="Select State" options={states} value={selectedState} onChange={handleStateChange} isSearchable styles={customStyles}/>
                        <Select placeholder="Select Dioceses" options={dioceses} value={selectedDiocese} onChange={handleDioceseChange} isSearchable styles={customStyles}/>
                        <Select placeholder="Select Cities" options={cities} value={selectedCity} onChange={handleCityChange} isSearchable styles={customStyles}/>
                    </div>
                </div>
                <div>
                    <button className="profile-change" onClick={handleSaveChanges}>Save the Changes</button>
                    <button className="profile-change" onClick={handleCancle} >Cancle</button>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faSortDown, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { API_PATH } from '../API_PATH';
import "./CSS/PeopleHome.css";
import { Following } from './Following';
import { PrayersList } from './PrayersList';
import { PeopleProfile } from './PeopleProfile';

export const Home = () => {

    const navigate = useNavigate();
    const [image, setImage] = useState(`${process.env.PUBLIC_URL}/images/dp.png`);
    const [email, setEmail] = useState('');
    const [isEmailReadOnly, setIsEmailReadOnly] = useState(false);
    const [sub, setSub] = useState('');
    const [peopleName, setPeopleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDiocese, setSelectedDiocese] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedChurch, setSelectedChurch ] =useState(null);
    const [showWelcome, setShowWelcome] = useState(false);
    const [prefix, setPrefix] = useState('Mr');

    // Changes based on thomas
    const [inputs, setInputs] = useState({
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        option5: ''
    });

    const [showInput, setShowInput] = useState({
        input1: false,
        input2: false,
        input3: false,
        input4: false,
        input5: false
    });

    const [options, setOptions] = useState([]); 
    const [stateOptions, setStateOptions] = useState([]); 
    const [dioceseOptions, setDioceseOptions] = useState([]); 
    const [cityOptions, setCityOptions] = useState([]);
    const [churchOptions, setChurchOptions] = useState([]);
    const [selectedCountryId, setSelectedCountryId] = useState(null); 
    const [selectedStateId, setSelectedStateId] = useState(null); 
    const [selectedDioceseId, setSelectedDioceseId] = useState(null);
    const [selectedCityId, setSelectedCityId] = useState(null);

    useEffect(() => {
        const storedEmail = sessionStorage.getItem('userEmail');
        const userSub = sessionStorage.getItem('userSub');

        if (!storedEmail && !userSub ) {
            navigate('/people');
        }

        if (storedEmail) {
          setEmail(storedEmail);
          setIsEmailReadOnly(true);
      }

      if (userSub) {
          setSub(userSub);
      }
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${API_PATH}/pray_for_priest/country.php`);
                const countries = response.data.map(country => ({
                    value: country.id,
                    label: country.name,
                }));
                setOptions(countries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, [navigate]);

    useEffect(() => {
        if (selectedCountryId) {
            const fetchStates = async () => {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/state.php?country_id=${selectedCountryId}`);
                    const states = response.data.map(state => ({
                        value: state.id,
                        label: state.name,
                    }));
                    setStateOptions(states);
                } catch (error) {
                    console.error('Error fetching states:', error);
                }
            };
            fetchStates();
        }
    }, [selectedCountryId]);

    // Fetch dioceses based on selected state
    useEffect(() => {
        if (selectedStateId) {
            const fetchDioceses = async () => {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/diocese.php?state_id=${selectedStateId}`);
                    const dioceses = response.data.map(diocese => ({
                        value: diocese.id,
                        label: diocese.name,
                    }));
                    setDioceseOptions(dioceses);
                } catch (error) {
                    console.error('Error fetching dioceses:', error);
                }
            };
            fetchDioceses();
        }
    }, [selectedStateId]);

    useEffect(() => {
        if (selectedDioceseId) {
            const fetchCities = async () => {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/city.php?diocese_id=${selectedDioceseId}`);
                    const cities = response.data.map(city => ({
                        value: city.id,
                        label: city.name,
                    }));
                    setCityOptions(cities);
                } catch (error) {
                    console.error('Error fetching cities:', error);
                }
            };
            fetchCities();
        }
    }, [selectedDioceseId]);

    // Fetch churches based on selected city
    useEffect(() => {
        if (selectedCityId) {
            const fetchChurches = async () => {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/church.php?city_id=${selectedCityId}`);
                    const churches = response.data.map(church => ({
                        value: church.id,
                        label: church.name,
                    }));
                    setChurchOptions(churches);
                } catch (error) {
                    console.error('Error fetching churches:', error);
                }
            };
            fetchChurches();
        }
    }, [selectedCityId]);

    useEffect(() => {
      const storedEmail = sessionStorage.getItem("userEmail");
      const storedSub = sessionStorage.getItem("userSub");

      // Fetch all data from `people_detais` table
      axios.get(`${API_PATH}/pray_for_priest/get_people_details.php`)
          .then(response => {
              // Check session storage against the fetched data
              const matchedPeople = response.data.find(people => {
                  return (storedEmail && people.email === storedEmail) ||
                         (storedSub && people.sub === storedSub);
              });

              if (matchedPeople) {
                  setShowWelcome(true);
                  setEmail(matchedPeople.email);
                  setSub(matchedPeople.sub);
              } else {
                  setShowWelcome(false);
              }
          })
          .catch(error => console.error("Error fetching people details:", error));
  }, []);

  const handleEmailChange = (event) => {
      setEmail(event.target.value);
  };

  const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
          const fileType = file.type.split('/')[1]; // Get the file type (jpg, png, etc.)
          if (fileType === 'jpeg' || fileType === 'png') {
              const reader = new FileReader();
              reader.onloadend = () => {
                  setImage(reader.result); // Set the selected image as the profile picture
              };
              reader.readAsDataURL(file); // Read the file as a data URL
          } else {
              alert("Please select a valid image file (JPEG or PNG).");
          }
      }
  };

  const handleSelectChange = (selectedOption, inputType) => {

    if (selectedOption.value === 'Other') {
        setShowInput({ ...showInput, [inputType]: true });
        return;
    }

    setInputs({ ...inputs, [inputType]: selectedOption.label });

    // Handle other inputType changes
    if (inputType === 'input1') {  // Country
        setInputs({
            ...inputs,
            option1: selectedOption.label,
            option2: null,
            option3: null,
            option4: null,
            option5: null,
        });
        setSelectedCountry(selectedOption.label)
        setSelectedCountryId(selectedOption.value);
        setSelectedStateId(null);
        setStateOptions([]);
        setDioceseOptions([]);
        setCityOptions([]);
        setChurchOptions([]);
    } else if (inputType === 'input2') {  // State
        setInputs({
            ...inputs,
            option2: selectedOption.label,
            option3: null,
            option4: null,
            option5: null,
        });
        setSelectedState(selectedOption.label)
        setSelectedStateId(selectedOption.value);
        setDioceseOptions([]);
        setCityOptions([]);
        setChurchOptions([]);
    } else if (inputType === 'input3') {  // Diocese
        setInputs({
            ...inputs,
            option3: selectedOption.label,
            option4: null,
            option5: null,
        });
        setSelectedDiocese(selectedOption.label)
        setSelectedDioceseId(selectedOption.value);
        setCityOptions([]);
        setChurchOptions([]);
    } else if (inputType === 'input4') {  // City
        setInputs({
            ...inputs,
            option4: selectedOption.label,
            option5: null,
        });
        setSelectedCity(selectedOption.label)
        setSelectedCityId(selectedOption.value);
        setChurchOptions([]);
    } else if (inputType === 'input5') {  // Church
        setInputs({
            ...inputs,
            option5: selectedOption.label,
        });
        setSelectedChurch(selectedOption.label)
    }
};

const handleSave = async (optionKey) => {
    const value = inputs[optionKey];

    if (!value.trim()) {
        alert(`${optionKey.replace('option', 'Option ')} name cannot be empty.`);
        return;
    }

    let apiUrl;

    if (optionKey === 'option1') {
        apiUrl = `${API_PATH}/pray_for_priest/country.php`;
    } else if (optionKey === 'option2') {
        apiUrl = `${API_PATH}/pray_for_priest/state.php`;
        if (!selectedCountryId) {
            alert("Please select a country before saving the state.");
            return;
        }
    } else if (optionKey === 'option3') {
        apiUrl = `${API_PATH}/pray_for_priest/diocese.php`;
        if (!selectedStateId) {
            alert("Please select a state before saving the diocese.");
            return;
        }
    } else if (optionKey === 'option4') {
        apiUrl = `${API_PATH}/pray_for_priest/city.php`; 
        if (!selectedDioceseId) {
            alert("Please select a diocese before saving the city.");
            return;
        }
    } else {
        apiUrl = `${API_PATH}/pray_for_priest/church.php`;
        if (!selectedCityId) {
            alert("Please select a city before saving the church.");
            return;
        }
    }

    try {
        await axios.post(apiUrl, {
            name: value,
            country_id: optionKey === 'option2' ? selectedCountryId : undefined, // Pass country_id for state only
            state_id: optionKey === 'option3' ? selectedStateId : undefined, // Pass state_id for diocese only
            diocese_id: optionKey === 'option4' ? selectedDioceseId : undefined,
            city_id: optionKey === 'option5' ? selectedCityId : undefined,
        });

        // Fetch updated countries, states, or dioceses after saving
        if (optionKey === 'option1') {
            const response = await axios.get(`${API_PATH}/pray_for_priest/country.php`);
            const countries = response.data.map(country => ({
                value: country.id,
                label: country.name,
            }));
            setOptions(countries);
        } else if (optionKey === 'option2') {
            const response = await axios.get(`${API_PATH}/pray_for_priest/state.php?country_id=${selectedCountryId}`);
            const states = response.data.map(state => ({
                value: state.id,
                label: state.name,
            }));
            setStateOptions(states);
        } else if (optionKey === 'option3') {
            const response = await axios.get(`${API_PATH}/pray_for_priest/diocese.php?state_id=${selectedStateId}`);
            const dioceses = response.data.map(diocese => ({
                value: diocese.id,
                label: diocese.name,
            }));
            setDioceseOptions(dioceses);
        } else if (optionKey === 'option4') {
            const response = await axios.get(`${API_PATH}/pray_for_priest/city.php?diocese_id=${selectedDioceseId}`);
            const cities = response.data.map(city => ({
                value: city.id,
                label: city.name,
            }));
            setCityOptions(cities);
        } else {
            const response = await axios.get(`${API_PATH}/pray_for_priest/church.php?city_id=${selectedCityId}`);
            const churches = response.data.map(church => ({
                value: church.id,
                label: church.name,
            }));
            setChurchOptions(churches);
        }

        setShowInput({ ...showInput, [optionKey]: false });
        setInputs({ ...inputs, [optionKey]: '' });
    } catch (error) {
        console.error('Error saving:', error);
    }
};

const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
    };

  const customStyles = {
      control: (provided) => ({
          ...provided,
          border: 'none', 
          borderBottom: '2px solid #1F5258',
          borderRadius: 0,
          paddingBottom: '5px',
          cursor: "pointer",
          paddingLeft: '10px',
      }),
      placeholder: (provided) => ({
          ...provided,
          color: '#1F5258', 
          fontWeight: 'bold',
          opacity: '0.7'
      }),
      indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
      }),
      dropdownIndicator: (provided) => ({
          ...provided,
          color: '#1F5258', 
          padding: 4,
      }),
  };

  const handleFormSubmit = (event) => {
      event.preventDefault();

      const missingFields = [];

      // Check for missing fields and add them to the array
      if (!email) missingFields.push("Email");
      if (!peopleName) missingFields.push("People Name");
      if (!lastName) missingFields.push("Last Name");
      if (!contactNumber) missingFields.push("Contact Number");
      if (!selectedCountry) missingFields.push("Country");
      if (!selectedState) missingFields.push("State");
      if (!selectedDiocese) missingFields.push("Diocese");
      if (!selectedCity) missingFields.push("City");
      if (!selectedChurch) missingFields.push("Church");
      if (!image || image === `${process.env.PUBLIC_URL}/images/dp.png`) missingFields.push("Image");

      // If there are missing fields, show them in the alert
      if (missingFields.length > 0) {
          alert("Please fill in the following required fields: " + missingFields.join(", "));
          return;
      }

      const fullPeopleName = `${prefix}. ${peopleName}`;

      const formData = new FormData();
      formData.append("email", email);
      formData.append("sub", sub);
      formData.append("peopleName", fullPeopleName);
      formData.append("lastName", lastName);
      formData.append("contactNumber", contactNumber);
      formData.append("country", selectedCountry);
      formData.append("state", selectedState);
      formData.append("diocese", selectedDiocese);
      formData.append("city", selectedCity);
      formData.append("church", selectedChurch);
      formData.append("image", image);

      axios.post(`${API_PATH}/pray_for_priest/insert_people.php`, formData)
          .then(response => {
              alert("People information saved successfully!");
              setPeopleName("");
              setLastName("");
              setEmail("");
              setContactNumber("");
              setSelectedCountry(null);
              setSelectedState(null);
              setSelectedDiocese(null);
              setSelectedCity(null);
              setSelectedChurch(null);
              setShowWelcome(true)
              setImage(`${process.env.PUBLIC_URL}/images/dp.png`);
          })
          .catch(error => console.error("Error submitting form:", error));
  };

    console.log("Selected Country:", selectedCountry);
    console.log("Selected State:", selectedState);
    console.log("Selected Diocese:", selectedDiocese);
    console.log("Selected City:", selectedCity);
    console.log("Selected Church:", selectedChurch);


  const handlePrefixChange = (e) => {
    setPrefix(e.target.value);
  };

  return (
    <div>
      {showWelcome ? (
                <div className='welcome'>
                    <PeopleHome/>
                </div>
            ) : (
            <div className="container peoples-form">
                <div className="row align-items-center people-detail">
                    <div className="col-md-2 text-center text-md-start p-4">
                        <img src={`${process.env.PUBLIC_URL}/images/luke-logo.png`} alt="Logo" className="img-fluid" />
                    </div>
                    <div className="col-12 col-md-8 d-flex justify-content-center">
                        <div className="people-form">
                            <form onSubmit={handleFormSubmit} >
                                <div className="people-dp">
                                    <img className="img-fluid dp-img" src={image} alt="Profile" />
                                    <button type="button" className="img-icon" onClick={() => document.getElementById('fileInput').click()}><FontAwesomeIcon icon={faImage} /></button>
                                    <input type="file" style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={handleFileChange} id="fileInput" />
                                </div>
                                <div className="form-div-input d-flex">
                                    <select className='prefix' value={prefix} onChange={handlePrefixChange}>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms">Ms</option>
                                    </select>
                                    <input className="w-100 form-input" type="text" placeholder="First Name" value={peopleName} onChange={(e) => setPeopleName(e.target.value)} required /><br />
                                </div>
                                <div className="form-div-input">
                                    <input className="w-100 form-input" type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required /><br />
                                </div>
                                <div className="form-div-input">
                                    {isEmailReadOnly ? (
                                        <input className="w-100 form-input" type="email" placeholder="Email ID" value={email} readOnly />
                                    ) : (
                                        <input className="w-100 form-input" type="email" placeholder="Email ID" value={email} onChange={handleEmailChange}  required />
                                    )}
                                    <br />
                                </div>
                                <div className="form-div-input">
                                    <input className="w-100 form-input" type="number" placeholder="Contact Number" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} required /><br />    
                                </div>
                                <div>
                                    {!showInput.input1 ? (
                                        <div>
                                            <Select
                                                placeholder="Country"
                                                name="option1"
                                                value={inputs.option1 ? options.find(option => option.label === inputs.option1) : null}
                                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'input1')}
                                                options={[...options, { value: 'Other', label: 'Other' }]}
                                                isSearchable
                                                styles={customStyles}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="text"
                                                name="option1"
                                                value={inputs.option1}
                                                onChange={handleInputChange}
                                                placeholder="Enter country name"
                                                className='form-control'
                                            />
                                            <button className='butto' onClick={() => handleSave('option1')}>Save</button>
                                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input1: false })}>Cancel</button>
                                        </div>
                                    )}
                                </div>
                                {/* State Selection */}
                                <div>
                                    {!showInput.input2 ? (
                                        <div>
                                            <Select
                                                placeholder="State"
                                                name="option2"
                                                value={inputs.option2 ? stateOptions.find(option => option.label === inputs.option2) : null}
                                                onChange={(selectedOption) => {
                                                    handleSelectChange(selectedOption, 'input2');
                                                    setSelectedStateId(selectedOption.value); // Set the selected state ID
                                                }}
                                                options={[...stateOptions, { value: 'Other', label: 'Other' }]}
                                                isSearchable
                                                styles={customStyles}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="text"
                                                name="option2"
                                                value={inputs.option2}
                                                onChange={handleInputChange}
                                                placeholder="Enter state name"
                                                className='form-control'
                                            />
                                            <button className='butto' onClick={() => handleSave('option2')}>Save</button>
                                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input2: false })}>Cancel</button>
                                        </div>
                                    )}
                                </div>
                                {/* Diocese Selection */}
                                <div>
                                    {!showInput.input3 ? (
                                        <div>
                                            <Select
                                                placeholder="Diocese"
                                                name="option3"
                                                value={inputs.option3 ? dioceseOptions.find(option => option.label === inputs.option3) : null}
                                                onChange={(selectedOption) => {
                                                    handleSelectChange(selectedOption, 'input3');
                                                    setSelectedDioceseId(selectedOption.value); // Set the selected diocese ID
                                                }}
                                                options={[...dioceseOptions, { value: 'Other', label: 'Other' }]}
                                                isSearchable
                                                styles={customStyles}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="text"
                                                name="option3"
                                                value={inputs.option3}
                                                onChange={handleInputChange}
                                                placeholder="Enter diocese name"
                                                className='form-control'
                                            />
                                            <button className='butto' onClick={() => handleSave('option3')}>Save</button>
                                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input3: false })}>Cancel</button>
                                        </div>
                                    )}
                                </div>
                                {/* Cities Selection */}
                                <div>
                                    {!showInput.input4 ? (
                                        <div>
                                            <Select
                                                placeholder="City"
                                                name="option4"
                                                value={inputs.option4 ? cityOptions.find(option => option.label === inputs.option4) : null}
                                                onChange={(selectedOption) => {
                                                    handleSelectChange(selectedOption, 'input4');
                                                    setSelectedCityId(selectedOption.value); // Set the selected city ID
                                                }}
                                                options={[...cityOptions, { value: 'Other', label: 'Other' }]}
                                                isSearchable
                                                styles={customStyles}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="text"
                                                name="option4"
                                                value={inputs.option4}
                                                onChange={handleInputChange}
                                                placeholder="Enter city name"
                                                className='form-control'
                                            />
                                            <button className='butto' onClick={() => handleSave('option4')}>Save</button>
                                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input4: false })}>Cancel</button>
                                        </div>
                                    )}
                                </div>
                                {/* Church Selection */}
                                <div>
                                    {!showInput.input5 ? (
                                        <div>
                                            <Select
                                                placeholder="Church"
                                                name="option5"
                                                value={inputs.option5 ? churchOptions.find(option => option.label === inputs.option5) : null}
                                                onChange={(selectedOption) => {
                                                    handleSelectChange(selectedOption, 'input5');
                                                }}
                                                options={[...churchOptions, { value: 'Other', label: 'Other' }]}
                                                isSearchable
                                                styles={customStyles}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="text"
                                                name="option5"
                                                value={inputs.option5}
                                                onChange={handleInputChange}
                                                placeholder="Enter church name"
                                                className='form-control'
                                            />
                                            <button className='butto' onClick={() => handleSave('option5')}>Save</button>
                                            <button className='butto_cancel' onClick={() => setShowInput({ ...showInput, input5: false })}>Cancel</button>
                                        </div>
                                    )}
                                </div>
                                <div className="text-center">
                                    <button className="form-btn mt-3" type="submit">SUBMIT</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
  );
};


const PeopleHome = () => {

  const [searchQuery, setSearchQuery] = useState('');
  const [allPriests, setAllPriests] = useState([]);
  const [peopleData, setPeopleData] = useState([]);
  const [suggestedPriests, setSuggestedPriests] = useState([]);
  const [followData, setFollowData] = useState([]);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    axios.get(`${API_PATH}/pray_for_priest/get_follow_priests.php`)
        .then(response => {
            setFollowData(response.data);
        })
        .catch(error => console.error("Error fetching follow data:", error));
    }, []);

  useEffect(() => {
      const storedEmail = sessionStorage.getItem("userEmail");
      const storedSub = sessionStorage.getItem("userSub");

      // Fetch all data from `priests_detais` table
      axios.get(`${API_PATH}/pray_for_priest/get_people_details.php`)
          .then(response => {

              // Check session storage against the fetched data
              const matchedPeople = response.data.find(people => {
                  return (storedEmail && people.email === storedEmail) ||
                         (storedSub && people.sub === storedSub);
              });

              if (matchedPeople) {
                  setPeopleData(matchedPeople);
              }
          })
          .catch(error => console.error("Error fetching people details:", error));
  }, []);

	useEffect(() => {
		// Fetch all priests and filter based on country and city
		axios.get(`${API_PATH}/pray_for_priest/get_priest_details.php`)
			.then(response => {
				const priestsWithFollowStatus = response.data.map(priest => {
                    const isFollowed = followData.some(follow =>
                        follow.people_id === peopleData.people_id && follow.priest_id === priest.priest_id
                    );
                    return { ...priest, followed: isFollowed };
                });
                setAllPriests(priestsWithFollowStatus);
    
                // Filter by location initially
                const locationFilteredPriests = priestsWithFollowStatus.filter(priest => 
                    priest.country === peopleData.country && priest.city === peopleData.city
                );
				setSuggestedPriests(locationFilteredPriests);
			})
			.catch(error => console.error("Error fetching priests details:", error));
	}, [peopleData, followData]);

    const displayedPriests = searchQuery
    ? allPriests.filter(priest => 
        priest.priest_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : suggestedPriests;

    const handleLogout = () => {
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('userSub');
        navigate('/people'); 
    };

  const handleFollowToggle = (priest) => {
    const data = {
        people_id: peopleData.people_id,
        priest_id: priest.priest_id,
    };

    axios.post(`${API_PATH}/pray_for_priest/follow_priest.php`, data)
        .then(response => {
            if (response.data.status === 'followed') {
                // Update the priest's follow status to unfollow
                setSuggestedPriests(prevPriests => 
                    prevPriests.map(p => 
                        p.priest_id === priest.priest_id ? { ...p, followed: true } : p
                    )
                );
                setAllPriests(prevAllPriests =>
                    prevAllPriests.map(p =>
                        p.priest_id === priest.priest_id ? { ...p, followed: true } : p
                    )
                );
            } else if (response.data.status === 'unfollowed') {
                // Update the priest's follow status to follow
                setSuggestedPriests(prevPriests => 
                    prevPriests.map(p => 
                        p.priest_id === priest.priest_id ? { ...p, followed: false } : p
                    )
                );
                setAllPriests(prevAllPriests =>
                    prevAllPriests.map(p =>
                        p.priest_id === priest.priest_id ? { ...p, followed: false } : p
                    )
                );
            }
        })
        .catch(error => console.error("Error toggling follow status:", error));
    };

    const renderSectionHandler = (section) => {
        setActiveSection(section);  
    };

  const renderSection = () => {
      switch (activeSection) {
          case 'home':
              return <div><p><PrayersList/></p></div>;
          case 'following':
              return <div><Following/></div>; 
          case 'profile':
              return <div><PeopleProfile renderSectionHandler={renderSectionHandler}/></div>; 
          default:
              return null;
      }
  };

  return(
      <div>
          <div className="people-home">
              <div className='people-container-home'>
                  <div className="log-div" >
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/luke-logo.png`} alt="Logo" />
                  </div>
                  <div className='mt-5'>
                      <div className='people-div' onClick={() => setActiveSection('home')}>
                          <h6 className={`people-h6 ${activeSection === 'home' ? 'active' : ''}`}>Home</h6>
                      </div>
                      <div className='people-div' onClick={() => setActiveSection('following')}>
                          <h6 className={`people-h6 ${activeSection === 'following' ? 'active' : ''}`}>Following</h6>
                      </div>
                      <div className='people-div' onClick={() => setActiveSection('profile')}>
                          <h6 className={`people-h6 ${activeSection === 'profile' ? 'active' : ''}`}>Profile</h6>
                      </div>
                      <div className='people-div'>
                          <h6 className='people-h6' onClick={handleLogout}>Logout</h6>
                      </div>
                  </div>
              </div>
              <div className='render-section'>
                  {renderSection()}
              </div>
              <div>
					<div className='right-profile'>
						<div className='right-image'>
							{/* <img className='img-fluid right-image-border' src={`${process.env.PUBLIC_URL}/images/sample.png`} alt="" /> */}
							<img className='right-image-border' src={`${API_PATH}/pray_for_priest/${peopleData.profile_image}`} alt="" />
						</div>
						<div className='right-profile-value'>
							<p className='text mt-4'>{peopleData.people_name}</p>
							<p className='text mt-3' style={{cursor: "pointer"}}><FontAwesomeIcon icon={faSortDown} /></p>
						</div>
					</div>
					<div className='p-2'>
						<div className='search-profile'>
							<span className='search-icon' ><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
							<input className='search-bar' type="text" placeholder='Search Priest' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
						</div>
					</div>
					<p className='text mt-3'>Suggested for you</p>
					<div className='text-center'>
                        <div className='suggested-list p-2'>
                            {displayedPriests.map((priest, index) => (
                                <div className='suggested' key={index}>
                                    <div className='suggested-img-div'>
                                        <img className='img-fluid suggested-img' src={`${API_PATH}/pray_for_priest/${priest.profile_image}`} alt="" />
                                    </div>
                                    <div className='suggested-content text-start'>
                                        <div>
                                            <b className='sugg-priest'>{priest.priest_name}</b><br />
                                            <i className='sugg-loc'>{priest.country}</i><br />
                                            <i className='sugg-loc'>{priest.city}</i>
                                        </div>
                                        <div className='sugg-follow' onClick={() => handleFollowToggle(priest)}>
                                            <b className='sugg-follow-font'>{priest.followed ? "Unfollow" : "Follow"}</b>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
						<button className='sugg-btn'>VIEW MORE</button>
					</div>
              </div>
          </div>
      </div>
  )
};

import React, { useEffect} from "react";
import "./CSS/PriestHome.css"
import AOS from "aos";
import "aos/dist/aos.css";

export const MyPriest = () => {

    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <div>
            <section className="priestHeader container-fluid d-flex align-items-center flex-column flex-md-row">
                <div className="priest-text">
                    <p className="priest-h1">Ignite Prayer and Support for</p>
                    <p className="priest-h2">"Priests"</p>
                </div>
                <div className="text-center">
                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/priest-img.png`} alt="Priest" />
                </div>
            </section>
            <section style={{overflow: "hidden"}} className="mt-4">
                <div className="priest-text-content">
                    <div className="priest-luke">
                        <div data-aos="fade-right" className="text-center">
                            <h3 className="pri-h3">Luke 12:49 – Igniting a Flame of Prayer and Support for Priests</h3>
                        </div>
                        <div className="pri-text-container">
                            <div data-aos="fade-left">
                                <h4 className="pri-h4 mt-5" style={{textAlign: "start"}}>A Sacred Connection: Empowering Priests Through Prayer</h4>
                                <p className="pri-para mt-4">Every priest carries a profound mission: to shepherd souls, bring Christ to the world, and stand as a light of faith in an often dark and chaotic world. Yet, even the strongest among them need spiritual support. This is where you come in.</p>
                                <p className="pri-para">This platform is your sacred connection—a place where you can commit to praying for a priest by name, spiritually adopting them in their ministry, and becoming a vital part of their journey to bring grace and salvation to countless souls. Your intercessions and sacrifices are not just acts of devotion; they are spiritual lifelines that fortify the courage, strength, and resolve of priests as they lead the charge in the battle for souls.</p>
                            </div> 
                            <div data-aos="fade-right">
                                <h5 className="pri-h5">Through this movement, you can:</h5>
                                <ul className="pri-list">
                                    <li>Adopt a Priest: Choose a priest to spiritually support through prayer, fasting, and daily sacrifices.</li>
                                    <li>Pray for Priests in Need: Stand in the gap for priests facing challenges or in need of special graces.</li>
                                    <li>See the Impact of Your Prayers: Witness how your intercession strengthens priests in their mission, bringing hope and light to their ministry.</li>
                                </ul>
                            </div>
                            <div data-aos="fade-left">
                                <p className="pri-para">When you adopt a priest, you join a movement that bridges Heaven and Earth. Your prayers ignite a fire of grace that shields them in spiritual warfare, amplifies their efforts, and helps them reach souls that might otherwise remain lost. This is your mission field—a battlefield of love, faith, and perseverance, where your prayers equip priests with the strength to carry out their sacred calling.</p>
                            </div>
                            <div data-aos="fade-right">
                                <h5 className="pri-h5">Your Role in God’s Divine Plan</h5>
                                <p className="pri-list">By participating in this mission, you become an unseen warrior in the most critical fight of our times. As you adopt a priest, you align yourself with God’s call for a priestly nation, and your prayers become the spiritual ammunition they need to overcome challenges and lead boldly. </p>
                            </div>
                            <div data-aos="fade-left">
                                <h5 className="pri-h5">“The harvest is plentiful, but the labourers are few” (Luke 10:2)</h5>
                                <p className="pri-list">Priests have answered God’s call to labor in His vineyard. Now, it’s your turn to support them. Together, as a global community of intercessors, we can ensure no priest stands alone, no soul is forgotten, and no darkness goes unchallenged. Adopt a priest today and join the divine mission that transforms lives and echoes into eternity.</p>
                            </div>
                            <h6 data-aos="fade-right" className="pri-h6">Start Here. Pray Here. Ignite the Fire.</h6>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
import "./CSS/Terms.css";

export const Privacy = () => {
    return (
        <div className="d-flex justify-content-center p-5">
            <div className="w-75" style={{textAlign: "justify"}}>
                <div className="text-center">
                    <h3 className="term-h3">Privacy Policy for Luke1249.org</h3>
                </div>
                <div className="mt-4">
                    <p className="term-para">At www.luke1249.org, we are committed to respecting your privacy and safeguarding the personal information you share with us. This Privacy Policy explains how we collect, use, store, and protect your data when you visit our website and interact with our services. Please take a moment to read this policy to understand our views and practices regarding your personal data and how we will treat it.</p>
                    <p className="term-para">By accessing or using our website, you consent to the collection and use of your data as outlined in this Privacy Policy.</p>
                    <ol>
                        <li className="term-h3">Information We Collect</li>
                        <p className="term-para">We collect information that you provide to us voluntarily, as well as certain information that is automatically collected when you visit our website.</p>
                        <p className="term-para">When you engage with us through forms, sign-up for newsletters, submit prayer requests, or contact us directly, you may provide the following personal information:</p>
                        <ul className="term-list">
                            <li>Name</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Religious affiliation (optional, e.g., Christian denomination)</li>
                            <li>Mailing address (if requesting physical resources)</li>
                            <li>Payment details (if making a donation or purchasing items)</li>
                        </ul>
                        <p className="term-para">We collect information automatically through cookies, web beacons, and other tracking technologies. This includes:</p>
                        <ul className="term-list">
                            <li>IP address</li>
                            <li>Browser type and version</li>
                            <li>Device type and operating system</li>
                            <li>Pages visited and time spent on our website</li>
                            <li>Referring website or search engine keywords</li>
                            <li>Geographic location (approximate)</li>
                        </ul>
                        <p className="term-para">In some cases, you may provide sensitive data such as religious beliefs, prayer requests, or personal stories. This data is used only for the purposes intended (e.g., prayer ministry, newsletters, or personalized outreach). We take extra care to protect such sensitive data.</p>
                        <li className="term-h3">How We Use Your Information</li>
                        <p className="term-para">We use the data we collect for a variety of purposes to enhance your experience and provide relevant content. This includes, but is not limited to:</p>
                        <ul className="term-list">
                            <li><b>To communicate with you:</b>Sending newsletters, event invitations, prayer updates, and other communication that may be of interest.</li>
                            <li><b>To provide and improve our services:</b>We may use your data to enhance the content and functionality of our website, personalize your experience, and provide you with resources aligned with your interests and faith.</li>
                            <li><b>To process donations and purchases:</b>If you make a donation or purchase from us, we may collect payment information for the purpose of processing your transaction securely.</li>
                            <li><b>To improve our website:</b>We analyze user behavior and preferences to optimize website content, design, and functionality.</li>
                            <li><b>To offer targeted advertisements:</b>If you opt-in, we may use data for retargeting or advertising purposes through platforms like Google Ads or Facebook.</li>
                        </ul>
                        <li className="term-h3">How We Share Your Information</li>
                        <p className="term-para">We respect your privacy and will not sell, rent, or trade your personal information. However, we may share your information in the following cases:</p>
                        <ul className="term-list">
                            <li><b>With trusted third-party service providers:</b>We may share your data with companies that provide services on our behalf, such as email marketing platforms (e.g., Mailchimp), payment processors (e.g., Stripe), or website analytics providers (e.g., Google Analytics). These parties are obligated to handle your information securely and only as necessary to perform their services.</li>
                            <li><b>For legal compliance:</b>If required by law, regulation, or legal process (e.g., subpoena, court order), we may disclose your information to comply with applicable laws or to protect our rights, property, or safety, or the rights, property, and safety of others.</li>
                            <li><b>With your consent:</b>In some cases, we may share your information with your consent or at your direction, such as when you choose to submit personal stories or requests to be shared publicly.</li>
                        </ul>
                        <li className="term-h3">Data Retention and Security</li>
                        <p className="term-para">We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. If you request that we delete your data, we will comply, unless we are required to keep certain information for legal or operational purposes.</p>
                        <p className="term-para">We use a combination of administrative, technical, and physical safeguards to protect your personal information from unauthorized access, alteration, or destruction. However, no data transmission or storage method is 100% secure, and we cannot guarantee absolute security.</p>
                        <p className="term-para">We encrypt sensitive information (such as payment details) using industry-standard encryption technologies to ensure the security of your transactions.</p>
                        <li className="term-h3">Cookies and Tracking Technologies</li>
                        <p className="term-para">We use cookies and other tracking technologies to enhance your experience on our site, analyze site usage, and deliver relevant content and advertising. Cookies are small text files stored on your device. By using our site, you consent to the use of cookies in accordance with this Privacy Policy.</p>
                        <p className="term-para">You can manage cookies through your browser settings. Please note that disabling cookies may limit your ability to use certain features of the website.</p>
                        <li className="term-h3">User Rights and Choices</li>
                        <p className="term-para">You have the right to request access to your personal data, correct any inaccuracies, or delete your information. To exercise these rights, please contact us at [Your Contact Email].</p>
                        <p className="term-para">If you no longer wish to receive marketing emails or newsletters from us, you can opt-out by:</p>
                        <ul className="term-list">
                            <li>Clicking the "unsubscribe" link at the bottom of any email.</li>
                            <li>Contacting us directly via email at [Your Contact Email].</li>
                        </ul>
                        <p className="term-para">You may request that we erase your personal data under certain conditions. Please note that we may need to retain certain information for legal or legitimate business purposes.</p>
                        <li className="term-h3">International Data Transfers</li>
                        <p className="term-para">If you are accessing our website from outside the United States (or your country of residence), please be aware that your data may be transferred to, and processed in, countries with different data protection laws. By using our website, you consent to the transfer of your information to countries outside your jurisdiction.</p>
                        <li className="term-h3">Children’s Privacy</li>
                        <p className="term-para">Our website is not directed at children under the age of 13, and we do not knowingly collect or solicit personal information from children. If we learn that we have inadvertently collected personal data from a child under 13, we will take steps to delete that information as soon as possible. If you are a parent or guardian and believe we have collected such data, please contact us at [Your Contact Email].</p>
                        <li className="term-h3">Third-Party Websites</li>
                        <p className="term-para">Our website may contain links to third-party websites that are not under our control. These websites have their own privacy policies, and we encourage you to review them. We are not responsible for the content or privacy practices of these external websites.</p>
                        <li className="term-h3">Changes to This Privacy Policy</li>
                        <p className="term-para">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the new Privacy Policy on our website and updating the "Effective Date" at the top of the page. We encourage you to review this policy periodically.</p>
                        <li className="term-h3">Contact Us</li>
                        <p className="term-para">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                        <p className="term-para">[Your Contact Email]</p>
                        <p className="term-para">[Your Contact Phone Number]</p>
                        <p className="term-para">[Your Physical Address]</p>
                    </ol>
                </div>
            </div>
        </div>
    )
}
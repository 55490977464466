import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CSS/adminHome.css';
import { Dashboard } from './Dashboard';
import { Church } from './Church';
import { Intercessors } from './Intercessors';
import { PriestAdmin } from './Priestadmin';
import { Post } from './Post';
import { Message } from './Message';

export const Home = () => {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('dashboard');

    useEffect(() => {
        const admin = sessionStorage.getItem('admin');
        if (!admin) {
            navigate('/admin');
        }
    }, [navigate]);

    const handleLogout = () => {
        sessionStorage.removeItem('admin');
        navigate('/admin'); 
    };

    const renderSection = () => {
        switch (activeSection) {
            case 'dashboard':
                return <Dashboard />;
            case 'intercessors':
                return <div><Intercessors/></div>; 
            case 'priest':
                return <div><PriestAdmin/></div>;
            case 'message':
                return <div><Message/></div>;
            case 'church':
                return <Church/>; 
            case 'post':
                return <div><Post/></div>;
            default:
                return null;
        }
    };

    return (
        <div>
            <div className="admin-home">
                <div className='admin-container'>
                    <div className="log-div" >
                        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/luke-logo.png`} alt="Logo" />
                    </div>
                    <div className='mt-5'>
                        <div className='nav-div' onClick={() => setActiveSection('dashboard')}>
                            <h6 className={`nav-h6 ${activeSection === 'dashboard' ? 'active' : ''}`}>Dashboard</h6>
                        </div>
                        <div className='nav-div' onClick={() => setActiveSection('intercessors')}>
                            <h6 className={`nav-h6 ${activeSection === 'intercessors' ? 'active' : ''}`}>Intercessors</h6>
                        </div>
                        <div className='nav-div' onClick={() => setActiveSection('priest')}>
                            <h6 className={`nav-h6 ${activeSection === 'priest' ? 'active' : ''}`}>Priest</h6>
                        </div>
                        <div className='nav-div' onClick={() => setActiveSection('message')}>
                            <h6 className={`nav-h6 ${activeSection === 'message' ? 'active' : ''}`}>Message</h6>
                        </div>
                        <div className='nav-div' onClick={() => setActiveSection('church')}>
                            <h6 className={`nav-h6 ${activeSection === 'church' ? 'active' : ''}`}>Church</h6>
                        </div>
                        <div className='nav-div' onClick={() => setActiveSection('post')}>
                            <h6 className={`nav-h6 ${activeSection === 'post' ? 'active' : ''}`}>Post</h6>
                        </div>
                        <div className='nav-div'>
                            <h6 className='nav-h6' onClick={handleLogout}>Logout</h6>
                        </div>
                    </div>
                </div>
                <div>
                    {renderSection()}
                </div>
            </div>
        </div>
    );
};

export default Home;

// import { useNavigate } from "react-router-dom";
import "./CSS/ChooseLogin.css";

export const ChooseSignUp = () => {

    // const navigate = useNavigate();

    const handleIntercessor = () => {
        // navigate('/people/register')
        window.open("#/people/register", "_blank")
    }

    const handlePriest = () => {
        // navigate('/priest/register')
        window.open("#/priest/register", "_blank")
    }
    return (
        <div>
            <div className="text-center mb-3 choose-top"></div>
            <section className="row justify-content-center choose-container container-fluid">
                <div className="col-12 col-md-5 col-lg-3 choose-single">
                    <div className="text-center">
                        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/people-logo.png`} alt="Intercessor" />
                    </div>
                    <p className="mt-3 text-center"><b>INTERCESSOR</b></p>
                    <div className="choose-text-bg">
                        <div className="choose-div text-center">
                            <p className="choose-slogan">"Begin Supporting Priests Through Prayer Here."</p>
                            <button onClick={handleIntercessor} className="choose-btn">Sign Up</button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-5 col-lg-3 choose-single">
                    <div className="text-center">
                        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/church-priest.png`} alt="Priest" />
                    </div>
                    <p className="mt-3 text-center"><b>PRIEST</b></p>
                    <div className="choose-text-sec-bg">
                        <div className="choose-div text-center">
                            <p className="choose-slogan">"Receive Prayer Support & Strength Here."</p>
                            <button onClick={handlePriest} className="choose-btn">Sign Up</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_PATH } from "../API_PATH";
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Necessary for react-chartjs-2
import "./CSS/Dashboard.css";

export const Dashboard = () => {
    const [priestData, setPriestData] = useState([]);
    const [peopleData, setPeopleData] = useState([]);
    const [filteredPriestData, setFilteredPriestData] = useState([]);
    const [filteredPeopleData, setFilteredPeopleData] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        // Fetch all priests
        axios.get(`${API_PATH}/pray_for_priest/get_priest_details.php`)
            .then(response => {
                const formattedData = response.data.map(item => ({
                    ...item,
                    created_at: moment(item.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
                }));
                setPriestData(formattedData);
                setFilteredPriestData(formattedData); // Initialize filtered data with all data
            })
            .catch(error => console.error("Error fetching priests details:", error));

        // Fetch all people
        axios.get(`${API_PATH}/pray_for_priest/get_people_details.php`)
            .then(response => {
                const formattedData = response.data.map(item => ({
                    ...item,
                    created_at: moment(item.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
                }));
                setPeopleData(formattedData);
                setFilteredPeopleData(formattedData); // Initialize filtered data with all data
            })
            .catch(error => console.error("Error fetching people details:", error));
    }, []);

    const filterDataByDate = () => {
        if (fromDate && toDate) {
            const from = moment(fromDate).startOf('day').toDate();
            const to = moment(toDate).endOf('day').toDate();

            const priestsFiltered = priestData.filter(item => item.created_at >= from && item.created_at <= to);
            const peopleFiltered = peopleData.filter(item => item.created_at >= from && item.created_at <= to);

            setFilteredPriestData(priestsFiltered);
            setFilteredPeopleData(peopleFiltered);
        } else {
            // If no date filter, show all data
            setFilteredPriestData(priestData);
            setFilteredPeopleData(peopleData);
        }
    };

    const generateDateRange = (startDate, endDate) => {
        const dates = [];
        let currentDate = moment(startDate);

        // Only proceed if the startDate is valid
        if (!currentDate.isValid()) {
            console.error("Invalid start date:", startDate);
            return [];
        }

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
            dates.push(currentDate.format('DD-MM-YYYY'));
            currentDate.add(1, 'days');
        }
        return dates;
    };

    const getDateWiseCounts = (data, dateRange) => {
        const dateCounts = {};
        data.forEach(item => {
            const date = moment(item.created_at).format('DD-MM-YYYY');
            dateCounts[date] = (dateCounts[date] || 0) + 1;
        });

        // Ensure all dates in the date range appear in the chart
        const counts = dateRange.map(date => dateCounts[date] || 0);
        return counts;
    };

    // Make sure that created_at values are valid before finding min/max dates
    const validDates = priestData.filter(item => moment(item.created_at).isValid()).map(item => item.created_at);
    const minDate = moment.min(validDates.map(date => moment(date)));
    const maxDate = moment.max(validDates.map(date => moment(date)));

    // Generate date range from valid start to end date
    const dateRange = fromDate && toDate ? generateDateRange(fromDate, toDate) : generateDateRange(minDate, maxDate);

    // Limit date range to 12 labels for the X-axis
    const limitedDateRange = dateRange.slice(0, 30); // Only keep the first 12 days or months

    const priestCounts = getDateWiseCounts(filteredPriestData, limitedDateRange);
    const peopleCounts = getDateWiseCounts(filteredPeopleData, limitedDateRange);

    const priestBarData = {
        labels: limitedDateRange,
        datasets: [
            {
                label: 'Priest Logins',
                data: priestCounts,
                backgroundColor: '#264A92',
                borderColor: '#264A92',
                borderWidth: 1,
            },
        ],
    };

    const peopleBarData = {
        labels: limitedDateRange,
        datasets: [
            {
                label: 'People Logins',
                data: peopleCounts,
                backgroundColor: '#264A92',
                borderColor: '#264A92',
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className='p-4'> 
            <div className='d-flex justify-content-center align-items-center mb-4'>
                <div>
                    <label className='chart-lable'>From Date</label>
                    <input className='chart-input' type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                </div>
                <div>
                    <label className='chart-lable'>To</label>
                    <input className='chart-input' type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                </div>
                <button className='chart-filter' onClick={filterDataByDate}>Filter</button>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ width: '45%' }}>
                    <div>
                        <Bar data={priestBarData} className='chart'/>
                    </div>
                </div>
                <div style={{ width: '45%' }}>
                    <div>
                        <Bar data={peopleBarData} className='chart'/>
                    </div>
                </div>
            </div>
            <div className='row p-4'>
                <div className='col-4'>
                    <div className='country-percentage'>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/images/us.png`} alt="Country" />
                        </div>
                        <p className='country-name'>United States of America</p>
                        <p className='count-numbers'>81%</p>
                    </div>
                    <div className='country-percentage'>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/images/usflag.png`} alt="Country" />
                        </div>
                        <p className='country-name'>Portugal</p>
                        <p className='count-numbers'>86%</p>
                    </div>
                    <div className='country-percentage'>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/images/flag.png`} alt="Country" />
                        </div>
                        <p className='country-name'>Australia</p>
                        <p className='count-numbers'>85%</p>
                    </div>
                    <div className='country-percentage'>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/images/au.png`} alt="Country" />
                        </div>
                        <p className='country-name'>United Kingdom</p>
                        <p className='count-numbers'>82%</p>
                    </div>
                </div>
                <div className='col'>
                    <div className='row'>
                        <div className='col'>
                            <p className='tot-head'>Total Intercessors</p>
                            <p className='tot-num'>{peopleData.length}</p>
                        </div>
                        <div className='col'>
                            <p className='tot-head'>Total Priest</p>
                            <p className='tot-num'>{priestData.length}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

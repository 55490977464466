import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../API_PATH';

export const Post = () => {

    const [prayerRequests, setPrayerRequests] = useState([]);
    const [allPriests, setAllPriests] = useState([]);
    const [prayerCount, setPrayerCount] = useState([]);

    useEffect(() => {
        // Fetch all prayer requests
        axios.get(`${API_PATH}/pray_for_priest/prayer_request.php`)
            .then(response => {
                const sortedPrayers = response.data.sort((a, b) => new Date(b.posted_on) - new Date(a.posted_on));
                setPrayerRequests(sortedPrayers);
            })
            .catch(error => console.error("Error fetching prayer requests:", error));
    }, []);

    useEffect(() => {
        // Fetch all priests
        axios.get(`${API_PATH}/pray_for_priest/get_priest_details.php`)
            .then(response => {
                setAllPriests(response.data);
            })
            .catch(error => console.error("Error fetching priests details:", error));
    }, []);

    useEffect(() => {
        // Fetch prayer count data
        axios.get(`${API_PATH}/pray_for_priest/prayering_support.php`)
            .then(response => {
                setPrayerCount(response.data);
            })
            .catch(error => console.error("Error fetching prayer count details:", error));
    }, []);

    const handleDelete = (id) => {
        axios.delete(`${API_PATH}/pray_for_priest/delete_prayer.php`, {
            data: {
                id: id
            }
        })
        .then(response => {
            if (response.data.status === 'success') {
                // Handle success, e.g., remove the deleted person from the UI
                setPrayerRequests(prayerRequests => prayerRequests.filter(prayer => prayer.id !== id));
            } else {
                alert('Failed to delete person');
            }
        })
        .catch(error => {
            console.error('Error deleting person:', error);
            alert('An error occurred while deleting the person');
        });
    };

    return (
        <div>
            <div className='d-flex justify-content-center'>
            <div className="prayers-list w-75" >
                {prayerRequests.map((prayer) => {
                    // Find the priest details by matching priest_id
                    const priest = allPriests.find(p => p.priest_id === prayer.priest_id);
                    const priestName = priest ? priest.priest_name : "Unknown Priest";
                    const profileImage = priest ? `${API_PATH}/pray_for_priest/${priest.profile_image}` : `${process.env.PUBLIC_URL}/images/dp.png`;

                    // Calculate total prayer count for this prayer_id
                    const totalPrayerCount = prayerCount.filter(entry => entry.prayer_id === prayer.prayer_id).length;

                    return (
                        <div className="prayers-single" key={prayer.id}>
                            <div className="text-center align-items-center">
                                <div className="prayers-img-div">
                                    <img className="img-fluid prayers-img" src={profileImage} alt={priestName} />
                                </div>
                                <p className="prayer-name">{priestName}</p>
                            </div>
                            <div>
                                <p className='prayers-text-content'>{prayer.prayer_text}</p>
                                <div className="prayers-count">
                                    <i className="count-prayers">
                                        <b className="count">{totalPrayerCount}</b> Prayers
                                    </i>
                                    <button className='danger-btn' onClick={() => handleDelete(prayer.id)}>Delete</button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            </div>
        </div>
    )
}
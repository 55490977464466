export const FAQs = () => {
    return (
        <div className="d-flex justify-content-center p-5">
            <div className="w-75" style={{textAlign: "justify"}}>
                <div className="text-center">
                    <h3 className="term-h3">FAQs: Your Questions Answered</h3>
                </div>
                <div>
                    <ol>
                        <li className="term-h3">What is this platform about?</li>
                        <p className="term-para">This platform connects intercessors and priests in a spiritual mission. Intercessors can offer prayers and sacrifices for priests, supporting their sacred mission. Priests can also share specific prayer needs, creating a global network of faith and support.</p>
                        <li className="term-h3">Who can join this platform?</li>
                        <p className="term-para">Anyone who feels called to support priests through prayer can join as an intercessor. Priests are also welcome to join, share their prayer needs, and receive spiritual support from the community.</p>
                        <li className="term-h3">How does the platform work for intercessors?</li>
                        <p className="term-para">Intercessors can:</p>
                        <ul className="term-list">
                            <li>Browse through profiles of priests.</li>
                            <li>Select priests to pray for, either based on specific prayer needs or personal inspiration.</li>
                            <li>Commit to praying for these priests and become part of a global prayer movement.</li>
                        </ul>
                        <li className="term-h3">How does it work for priests?</li>
                        <p className="term-para">Priests can:</p>
                        <ul className="term-list">
                            <li>Register and share their prayer needs.</li>
                            <li>Receive spiritual support from intercessors around the world.</li>
                            <li>Stay encouraged by knowing they are upheld by a community dedicated to their mission.</li>
                        </ul>
                        <li className="term-h3">Can intercessors and priests communicate directly?</li>
                        <p className="term-para">Direct communication is not standard but may be facilitated upon mutual consent, especially if priests wish to connect with intercessors supporting them.</p>
                        <li className="term-h3">Is there a fee to join this platform?</li>
                        <p className="term-para">No, joining the platform is completely free. It is a mission to unite in faith and prayer.</p>
                        <li className="term-h3">How can I choose which priests to pray for?</li>
                        <p className="term-para">You can select priests based on your heart's calling, their prayer requests, or their geographical location. The platform encourages you to follow the inspiration of the Holy Spirit in your choice.</p>
                        <li className="term-h3">What kinds of prayer requests do priests typically share?</li>
                        <p className="term-para">Priests may request prayers for:</p>
                        <ul className="term-list">
                            <li>Strength and guidance in their ministry.</li>
                            <li>Personal challenges or spiritual renewal.</li>
                            <li>Specific missions or responsibilities within their Church.</li>
                        </ul>
                        <li className="term-h3">How will I know my prayers are making a difference?</li>
                        <p className="term-para"> className="term-para"While the impact of prayer is often spiritual and unseen, priests can share updates or words of gratitude through the platform, allowing intercessors to feel connected to the fruits of their prayer.</p>
                        <li className="term-h3">What is the role of Saint Michael Centre for the Blessed Virgin Mary?</li>
                        <p className="term-para">This platform is a project of the Saint Michael Centre for the Blessed Virgin Mary, a ministry dedicated to promoting devotion, spiritual growth, and prayer missions around the world.</p>
                        <li className="term-h3">How can I become more involved?</li>
                        <p className="term-para">Aside from offering prayers, you can spread the word about the platform, invite others to join, and participate in special prayer campaigns hosted by the platform.</p>
                        <li className="term-h3">How is my privacy protected?</li>
                        <p className="term-para">The platform ensures that personal information shared by priests and intercessors remains confidential and is used solely to facilitate prayer support.</p>
                        <li className="term-h3">Are there other ways to support this mission?</li>
                        <p className="term-para">Yes! You can support through donations to the Saint Michael Centre for the Blessed Virgin Mary, volunteering, or participating in other initiatives aimed at strengthening the Church and its priests.</p>
                        <li className="term-h3">Can I pray for more than one priest?</li>
                        <p className="term-para">Absolutely! You can choose multiple priests to support in prayer and offer your intercession for their unique needs.</p>
                        <li className="term-h3">How can I contact the team for support or questions?</li>
                        <p className="term-para">You can reach us through the Contact Us page on the website or by emailing us directly. We’re here to assist you with any inquiries.</p>
                    </ol>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect} from "react";
import "./CSS/PriestHome.css"
import AOS from "aos";
import "aos/dist/aos.css";

export const Prayer = () => {

    useEffect(() => {
        AOS.init();
      }, []);


    return (
        <div>
            <section className="priestHeader container-fluid d-flex align-items-center flex-column flex-md-row">
                <div className="priest-text text-center">
                    <p className="priest-h1">Empowering Priests, Changing Lives </p>
                    <p className="priest-h2">"Through Prayer"</p>
                </div>
                <div className="text-center">
                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/prayer.png`} alt="Priest" />
                </div>
            </section>
            <section style={{overflow: "hidden"}} className="container mt-4">
                <div className="people-text-content">
                    <div className="peop-container">
                        <div data-aos="fade-right" className="text-center">
                            <h3 className="pri-h3">Luke 12:49 – A Light in Times of Need</h3>
                        </div>
                        <div data-aos="fade-left">
                            <h4 className="pri-h4 mt-4">Prayer Requests: Interceding for Priests, Empowering Souls</h4>
                            <p className="pri-para mt-3">Welcome to the Prayer Requests page—a place where intercessors and priests unite in prayer. Here, your prayer is not just a petition; it’s a divine mission connecting believers worldwide to strengthen the Church and bring grace to souls in need.</p>
                        </div>
                        <div data-aos="fade-right">
                            <h5 className="pri-h5">How It Works: A Powerful Partnership</h5>
                            <p className="pri-para">For Intercessors:</p>
                            <ul className="pri-list">
                                <li><b className="pri-h5">Sign Up and Choose:</b> Browse through priests and their prayer needs or simply choose to pray for a priest, even if no specific request is posted. Your prayers, regardless of the need, are a powerful support.</li>
                                <li><b className="pri-h5">Pray for the Priest:</b> Your prayers are lifelines, whether for strength, guidance, or grace.</li>
                                <li><b className="pri-h5">Stay Connected:</b> See how many intercessors are praying for the priest you support, knowing your prayers are part of a global effort.</li>
                            </ul>
                            <p className="pri-para">For Priests:</p>
                            <ul className="pri-list">
                                <li><b>Sign Up and Share Requests:</b> Share your prayer needs—strength, guidance, or support during difficult times.</li>
                                <li><b>Receive Prayers:</b> See how many are praying for you, knowing you are upheld by a worldwide community of faith.</li>
                            </ul>
                        </div>
                        <div data-aos="fade-left">
                            <h5 className="pri-h5">The Power of Prayer: A Global Mission</h5>
                            <p className="pri-para">When intercessors and priests unite in prayer, incredible things happen. Your prayers fuel a priest’s ministry, enabling them to lead others to Christ. For intercessors, this is a chance to be part of a global movement of faith, echoing through eternity.</p>
                        </div>
                        <div data-aos="fade-right">
                            <h5 className="pri-h5">Why Prayer Requests Matter:</h5>
                            <ul className="pri-list">
                                <li>Strengthen the Church: Your prayers are foundational to the strength of priests, empowering them in their mission.</li>
                                <li>Connect with a Global Community: Join believers worldwide, united in prayer and purpose.</li>
                                <li>Witness Divine Impact: See how your prayers help priests fulfil their divine calling and transform lives.</li>
                            </ul>
                        </div>
                        <div data-aos="fade-left">
                            <h5 className="pri-h6">Join the Mission: Pray. Support. Transform.</h5>
                            <p className="pri-spl-para">Your participation in this mission is of eternal significance. Together, we build a spiritual army that empowers priests and changes lives through prayer. Let your prayers spark a divine revolution in the hearts of priests and souls across the world.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
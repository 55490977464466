import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../API_PATH';
import * as XLSX from 'xlsx';

export const Message = () => {
    const [messageData, setMessageData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10);

    useEffect(() => {
        axios
            .get(`${API_PATH}/pray_for_priest/get_message.php`)
            .then((response) => {
                setMessageData(response.data);
            })
            .catch((error) => console.error("Error fetching message details:", error));
    }, []);

    const fetchMessahe = () => {
        axios.get(`${API_PATH}/pray_for_priest/get_message.php`)
            .then((response) => {
                setMessageData(response.data);
            })
            .catch((error) => console.error("Error fetching message details:", error));
    }

    // Calculate the pagination indices
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = messageData.slice(indexOfFirstRow, indexOfLastRow);

    // Calculate the pagination range
    const totalPages = Math.ceil(messageData.length / rowsPerPage);
    const maxPageButtons = 5;
    const startPage = Math.floor((currentPage - 1) / maxPageButtons) * maxPageButtons + 1;
    const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);
    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    // Event handlers for pagination
    const handlePrevious = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNext = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDelete = (id) => {
        axios.delete(`${API_PATH}/pray_for_priest/delete_message.php`, {
            data: {
                id: id
            }
        })
        .then(response => {
            if (response.data.status === 'success') {
                alert('Message Deleted')
                fetchMessahe();
            } else {
                alert('Failed to delete person');
            }
        })
        .catch(error => {
            console.error('Error deleting person:', error);
            alert('An error occurred while deleting the person');
        });
    };

    const handleDownload = () => {
        if (messageData.length === 0) {
            alert("No data available to download.");
            return;
        }
    
        // Prepare data for Excel
        const worksheet = XLSX.utils.json_to_sheet(messageData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Message");
    
        // Export to Excel file
        XLSX.writeFile(workbook, "MessageData.xlsx");
    };

    return (
        <div>
            <div className="table-container mt-4">
                <table className="w-100">
                    <thead className="text-center">
                        <tr className="table-tr">
                            <th className="table-th">First Name</th>
                            <th className="table-th">Last Name</th>
                            <th className="table-th">Email ID</th>
                            <th className="table-th">Subject</th>
                            <th className="table-th">Message</th>
                            <th className="table-action">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((message, index) => (
                            <tr key={index}>
                                <td className="table-td">{message.first_name}</td>
                                <td className="table-td">{message.last_name}</td>
                                <td className="table-td">{message.email}</td>
                                <td className="table-td">{message.subject}</td>
                                <td className="table-td">{message.message}</td>
                                <td className="text-center table-delete">
                                    <button className="danger-btn" onClick={() => handleDelete(message.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-between'>
                <div className="pagination">
                    <button
                        className="success-btn"
                        onClick={handlePrevious}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    {pageNumbers.map((pageNumber) => (
                        <button
                            key={pageNumber}
                            className={`success-btn ${
                                currentPage === pageNumber ? 'active' : ''
                            }`}
                            onClick={() => handlePageClick(pageNumber)}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <button
                        className="success-btn"
                        onClick={handleNext}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
                <div className="pagination">
                    <button onClick={handleDownload} className="success-btn">Download</button>
                </div>
            </div>
        </div>
    );
};

import "./CSS/Aboutus.css";
import React, { useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const AboutUs = () => {

    useEffect(() => {
        AOS.init();
      }, []);


    return (
        <div>
            <section className="priestHeader container-fluid d-flex align-items-center flex-column flex-md-row">
                <div className="about-text">
                    <p className="priest-h2">About Us</p>
                </div>
                <div className="text-center">
                    <img
                        className="img-fluid"
                        src={`${process.env.PUBLIC_URL}/images/aboutus.png`}
                        alt="Priest"
                    />
                </div>
            </section>
            <section style={{overflow: "hidden"}} className=" about-sec d-flex justify-content-center p-5">
                <div className="abo-text-content">
                    <div data-aos="fade-right" className="text-center">
                        <h3 className="abo-h3">Luke 12:49 – Empowering Faith, One Prayer At A Time.</h3>
                    </div>
                    <div data-aos="fade-left" className="mt-5">
                        <p className="abo-para"><b>At Luke 12:49,</b> we are on a divine mission to uplift priests and empower intercessors through the transformative power of prayer. As an initiative of the Saint Michael Center for the Blessed Virgin Mary, a globally recognized Catholic organization rooted in faith and devotion, we are committed to building bridges between priests and the faithful. <br /> The Saint Michael Center has been at the forefront of spiritual guidance and support, offering resources, prayer campaigns, and community outreach programs that inspire millions to deepen their faith. <br /> With their unwavering mission as our foundation, we have created this platform to unite intercessors and priests in a shared calling: to pray, support, and transform lives in faith. Whether you are a priest seeking strength through the prayers of others or an intercessor answering the call to support the Church's shepherds, our platform is a sanctuary for spiritual connection and empowerment. Together, we strengthen the mission of the Church, amplify its grace, and bring healing to a world in need. <br /> Here, every prayer is a step toward transformation, every intercession a testament to faith, and every connection a reminder of God’s unending love. Welcome to a movement where faith meets action, and lives are changed through the boundless power of prayer.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}
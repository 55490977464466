import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_PATH } from "../API_PATH"
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./CSS/Register.css";


export const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); 
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
    const [errors, setErrors] = useState({});
    const [isUsernameTaken, setIsUsernameTaken] = useState(false);

    const navigate = useNavigate();

    const validate = () => {
        const errors = {};
        if (!username) errors.username = 'Username is required';
        if (isUsernameTaken) errors.username = 'Username is already taken';
        if (!email) errors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(email)) errors.email = 'Email is invalid';
        if (!password) errors.password = 'Password is required';
        if (password && password.length < 6) errors.password = 'Password must be at least 6 characters';
        if (password && !/[A-Z]/.test(password)) errors.password = 'Password must contain at least one uppercase letter';
        if (password !== confirmPassword) errors.confirmPassword = 'Passwords do not match';
        return errors;
    };

    useEffect(() => {
        const checkUsernameAvailability = async () => {
            if (username) {
                try {
                    const response = await axios.get(`${API_PATH}/pray_for_priest/priest_register.php?username=${username}`);
                    setIsUsernameTaken(response.data.exists);
                    console.log(response.data);
                } catch (error) {
                    console.error('Error checking username availability:', error);
                }
            } else {
                setIsUsernameTaken(false);
            }
        };

        const debounceTimeout = setTimeout(() => {
            checkUsernameAvailability();
        }, 500);

        return () => clearTimeout(debounceTimeout); 
    }, [username]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length > 0) return;

        try {
            const response = await axios.post(`${API_PATH}/pray_for_priest/priest_register.php`, {
                username,
                email,
                password
            });
            setUsername("");
            setEmail(""); 
            setPassword("");
            setConfirmPassword("");
            navigate("/priest")
            console.log('Registration successful:', response.data);
        } catch (error) {
            console.error('Registration error:', error);
        }
    };

    const handleLogin = () => {
        navigate("/priest")
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className='bg-people-reg' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/priest-reg.jpg)` }}>
            <div className="d-flex justify-content-center align-items-center vh-100">
                <form className='people-reg glass-effect-reg p-4' onSubmit={handleSubmit}>
                    <div>
                        <label className='form-label'>Priest Name</label>
                        <input 
                            type="text" 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                            className='form-control'
                        />
                        {errors.username && <p style={{ color: 'red' }}>{errors.username}</p>}
                    </div>
                    <div>
                        <label className='form-label mt-2'>Email</label>
                        <input 
                            type="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            className='form-control'
                        />
                        {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                    </div>
                    <div>
                        <label className='form-label mt-2'>Password</label>
                        <div className="input-group">
                            <input 
                                type={showPassword ? "text" : "password"}
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                className='form-control'
                            />
                            <span className="input-group-text" style={{backgroundColor: "white", border: "none"}}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={togglePasswordVisibility} style={{ cursor: "pointer", fontSize: "12px" }}/>
                            </span>
                        </div>
                        {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                    </div>
                    <div>
                        <label className='form-label mt-2'>Confirm Password</label>
                        <div className="input-group">
                            <input 
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword} 
                                onChange={(e) => setConfirmPassword(e.target.value)} 
                                className='form-control'
                            />
                            <span className="input-group-text" style={{backgroundColor: "white", border: "none"}}>
                                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} onClick={toggleConfirmPasswordVisibility} style={{ cursor: "pointer", fontSize: "12px" }}/>
                            </span>
                        </div>
                        {errors.confirmPassword && <p style={{ color: 'red' }}>{errors.confirmPassword}</p>}
                    </div>
                    <button type="submit" className="button-reg mt-3" style={{ verticalAlign: "middle" }}><span className='span-reg'>Sign-UP</span></button>
                    <p className='mt-3 mb-0 ' style={{fontSize: "10px", textAlign: "end"}}>or already <b style={{fontSize: "12px", color:"#dc7633", cursor:"pointer"}} onClick={handleLogin}>LOGIN</b></p>
                </form>
            </div>
        </div>
    );
};

import { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./CSS/Navbar.css";
import AOS from "aos";
import "aos/dist/aos.css";

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const navigateTo = (path) => {
    navigate(path);
    setIsMobileMenuOpen(false); // Close mobile menu after navigation
  };

  const renderButton = (path, label) => {
    if (location.pathname !== path) {
      return (
        <li>
          <button onClick={() => navigateTo(path)} className="buttons">
            {label}
          </button>
        </li>
      );
    }
    return null;
  };

  return (
    <div className="container-fluid">
      <nav
        data-aos="fade-down"
        className="navbars d-flex justify-content-between align-items-center py-3"
      >
        <div onClick={() => navigateTo("/home")} className="logo" style={{ cursor: "pointer" }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/luke-logo.png`}
            alt="Luke logo"
          />
        </div>
        <button
          className="mobile-menu-icon d-lg-none"
          onClick={toggleMobileMenu}
        >
          <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
        </button>
        <div
          className={`${
            isMobileMenuOpen ? "mobile-menu-open" : "mobile-menu-close"
          }`}
        >
          <ul className="nav-links">
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-linkTag active" : "nav-linkTag inactive"
                }
                to="/home"
                onClick={toggleMobileMenu}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-linkTag active" : "nav-linkTag inactive"
                }
                to="/mypriest"
                onClick={toggleMobileMenu}
              >
                My Priest
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-linkTag active" : "nav-linkTag inactive"
                }
                to="/prayerrequest"
                onClick={toggleMobileMenu}
              >
                Prayer Request
              </NavLink>
            </li>
            <li
              className="dropdown"
              onMouseEnter={toggleDropdown}
              onMouseLeave={toggleDropdown}
            >
              <span className="nav-linkTag dropdown-toggle">More</span>
              {isDropdownOpen && (
                <ul className="dropdown-menu text-center">
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "dropdown-item active"
                          : "dropdown-item inactive"
                      }
                      to="/aboutus"
                      onClick={toggleMobileMenu}
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "dropdown-item active"
                          : "dropdown-item inactive"
                      }
                      to="/contact_us"
                      onClick={toggleMobileMenu}
                    >
                      Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "dropdown-item active"
                          : "dropdown-item inactive"
                      }
                      to="/mission"
                      onClick={toggleMobileMenu}
                    >
                      Mission
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            {renderButton("/chooselogin", "Login")}
            {renderButton("/choosesignup", "Sign Up")}
          </ul>
        </div>
      </nav>
    </div>
  );
};

import React, { useState } from "react";
import axios from "axios";
import { API_PATH } from "../API_PATH";
import './CSS/ContactUs.css';

export const ContactUs = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Make the POST request to the PHP backend
            const response = await axios.post(`${API_PATH}/pray_for_priest/receiving_email.php`, formData, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            alert(response.data.message);
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                subject: "",
                message: "",
            })
        } catch (error) {
            console.error("Error sending email:", error);
            alert("Failed to send the email. Please try again.");
        }
    };

    return (
        <div>
            <section className="priestHeader container-fluid d-flex align-items-center flex-column flex-md-row">
                <div className="about-text">
                    <p className="priest-h2">Contact</p>
                </div>
                <div className="text-center">
                    <img
                        className="img-fluid"
                        src={`${process.env.PUBLIC_URL}/images/contact.png`}
                        alt="Priest"
                    />
                </div>
            </section>
            <section className="d-flex justify-content-center p-5">
                <div className="res-para">
                    <div className="text-center">
                        <h3 className="term-h3">We Are Here for You</h3>
                    </div>
                    <p className="term-para">Thank you for visiting <b className="term-h3">Luke 12:49,</b> a project of the Saint Michael Center for the Blessed Virgin Mary. Whether you are a priest in need of spiritual support or an intercessor wishing to offer prayers, we are here to help. Feel free to reach out with any questions or requests you may have.</p>
                </div>
            </section>
            <section className="text-center mb-5">
                <div className="email-container">
                    <h5 className="term-spl-h5">Let’s Connect</h5>
                    <form className="email-form" onSubmit={handleSubmit}>
                        <div className="name-content">
                            <div>
                                <input className="email-input" type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
                            </div>
                            <div>
                                <input className="email-input" type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
                            </div>
                        </div>
                        <div>
                            <input className="email-input w-100 mt-4" type="email" name="email" placeholder="Email ID" value={formData.email} onChange={handleChange} required />
                        </div>
                        <div>
                            <input className="email-input w-100 mt-4" type="text" name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} />
                        </div>
                        <div>
                            <textarea className="email-input w-100 mt-4" name="message" placeholder="Message" value={formData.message} onChange={handleChange} required ></textarea>
                        </div>
                        <button className="email-btn" type="submit">Send</button>
                    </form>
                </div>
            </section>
            <section className="d-flex justify-content-center">
                <div className="w-75">
                    <p className="term-para">We are deeply committed to the mission of the Saint Michael Center, which helps foster faith and devotion through prayer. Let us support you in your spiritual journey, whether you're requesting prayers or offering them. Our team is ready to guide you every step of the way.</p>
                    <h5 className="term-h3">Get Involved</h5>
                    <p className="term-para">If you are an intercessor or priest looking to become part of this mission, you can join directly through our platform. Should you need any assistance, we are here to guide you.</p>
                </div>
            </section>
        </div>
    );
};
